import React, { useState, useContext, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { activitiesService, reservationsService } from "../../../../_services";
import { UserContext } from "../../../../contexts";
import arrow from "../../../../img/arrow_back_dark.png";
import CreateActivityReservation from "../../../reservations/activities/popup/createActivityReservation";

const localizer = momentLocalizer(moment);

const minTime = new Date();
minTime.setHours(8, 0, 0);
const maxTime = new Date();
maxTime.setHours(20, 0, 0);

const Reservations = (props) => {
  const colors = useContext(UserContext)?.colors;

  const [view, setView] = useState("month");
  const [dateSelected, setDateSelected] = useState(new Date());
  const [reservations, setReservations] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [slot, setSlot] = useState(null);

  const CustomToolbar = (toolbar) => {
    const styles = {
      toolbarContainer: {
        margin: 20,
      },
      button: {
        marginLeft: 20,
        marginRight: 20,
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        fontSize: 20,
        cursor: "pointer",
      },
      row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: props.isMobile ? "flex-end" : "flex-start",
        margin: 0,
        visibility: view === "month" && "hidden",
      },
      back: {
        fontSize: 10,
        marginLeft: 5,
        cursor: "pointer",
      },
      buttonBack: {
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        fontSize: props.isMobile ? 35 : 20,
        cursor: "pointer",
      },
      arrow: {
        height: 15,
        objectFit: "contain",
      },
    };

    const goToBack = () => {
      if (view === "month") {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      } else if (view === "day") {
        toolbar.date.setDate(toolbar.date.getDate() - 1);
      }

      toolbar.onNavigate("prev");
    };

    const goToNext = () => {
      if (view === "month") {
        toolbar.date.setDate(1);
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      } else if (view === "day") {
        toolbar.date.setDate(toolbar.date.getDate() + 1);
      }
      toolbar.onNavigate("next");
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span>
          {view === "day" && <b>{date.format("DD")} </b>}
          <b>{date.format("MMMM")}</b>
          <b> {date.format("YYYY")}</b>
        </span>
      );
    };

    return (
      <>
        <div style={styles.row}>
          <img
            src={arrow}
            style={styles.arrow}
            alt={"Go back"}
            onClick={() => setView("month")}
          />
          <p
            style={{
              fontSize: 13,
              color: colors?.darkgray,
              margin: 0,
              marginLeft: 5,
              fontWeight: "600",
            }}
            onClick={() => setView("month")}
          >
            Torna al calendario
          </p>
        </div>
        <div style={styles.toolbarContainer}>
          <button style={styles.button} onClick={goToBack}>
            &#8249;
          </button>
          <label>{label()}</label>
          <button style={styles.button} onClick={goToNext}>
            &#8250;
          </button>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (props.speaker) {
      activitiesService
        .getSpeakerReservations(props.speaker.id)
        .then((reservations) => setReservations(reservations));
    }
  }, [props.speaker]);
  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          height: "90%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <Calendar
          date={moment(dateSelected).toDate()}
          localizer={localizer}
          events={reservations}
          titleAccessor={(event) => {
            return event.attivita.nome;
          }}
          startAccessor={(event) => {
            return new Date(event.datetime_inizio);
          }}
          endAccessor={(event) => {
            return new Date(event.datetime_fine);
          }}
          style={{
            height: "95%",
            width: "100%",
          }}
          components={{
            toolbar: CustomToolbar,
          }}
          view={view}
          defaultView={view}
          min={minTime}
          max={maxTime}
          onNavigate={(date) => setDateSelected(new Date(date))}
          popup={true}
          onSelectEvent={(event) => {
            setDateSelected(event.datetime_inizio);
            if (view === "month") {
              setView("day");
            } else {
              reservationsService.getReservationInfo(event.id).then((info) => {
                setSlot(info);
                setShowModal(true);
              });
            }
          }}
          onDrillDown={(date) => {
            if (view === "month") {
              setView("day");
            }
            setDateSelected(date);
          }}
          dayPropGetter={(date) => {
            let dayStyle = {
              backgroundColor:
                moment(date).add(1, "days") < moment()
                  ? colors?.verylightgray
                  : "transparent",
            };
            return {
              className: "",
              style: dayStyle,
            };
          }}
          eventPropGetter={(event, start, end, isSelected) => {
            let newStyle = {
              backgroundColor: colors?.green,
              color: colors?.darkgray,
              borderRadius: 5,
              outline: "none",
              borderTop: "1px solid",
              borderBottom: "1px solid",
              borderRightWidth: window.innerWidth > 800 ? "8px solid" : "0",
              borderLeftWidth: window.innerWidth > 800 ? "8px solid" : "0",
              borderColor: colors?.green,
              fontSize: 13,
            };
            return {
              className: "",
              style: newStyle,
            };
          }}
        />
        {showModal && (
          <CreateActivityReservation
            closeModal={() => setShowModal(false)}
            colors={colors}
            onModify={false}
            activitySelected={slot}
            showReservation={true}
            canDelete={false}
          />
        )}
      </div>
    </div>
  );
};

export default Reservations;
