import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../../../../contexts";
import ReactExport from "react-export-excel";
import search from "../../../../img/search.png";
import download from "../../../../img/download.png";
import arrow from "../../../../img/arrow_back_dark.png";
import refresh from "../../../../img/refresh.png";
import { LanguageContext } from "../../../../containers/language";
import Table from "./table";
import BillingForm from "./billingForm";
import moment from "moment";
import ReactLoading from "react-loading";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";

import Pagination from "react-js-pagination";
import { eventsService } from "../../../../_services";
import GenerateInvoice from "../../../../components/popup/generateInvoice";
import { activitiesService } from "../../../../_services/activities.service";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const itemsPerPageOptions = [10, 20, 50, 100];

function isEmpty(field) {
  return field === "" || field === null || field === undefined;
}

const InvoicesManager = () => {
  const colors = useContext(UserContext)?.colors;
  const { dictionary } = useContext(LanguageContext);
  const [tabIndex, setTabIndex] = useState(0);
  const [openRecord, setOpenRecord] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [tempSearchKey, setTempSearchKey] = useState("");
  const [activities, setActivities] = useState([]);

  const [showGenerateInvoice, setShowGenerateInvoice] = useState(false);

  const [excelActivities, setExcelActivities] = useState([]);

  const orgId = useContext(UserContext)?.user.org_museo;

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const [activitiesLoaded, setActivitiesLoaded] = useState(false);

  const [loader, setLoader] = useState();
  const [tableLoaded, setTableLoaded] = useState();

  const [ordine, setOrdine] = useState("Data e ora attività");
  const [ascendente, setAscendente] = useState(true);

  const getBookedActivities = (
    start,
    limit,
    invoice,
    searchKey,
    ordine,
    ascendente
  ) => {
    setActivitiesLoaded(false);
    setTableLoaded(false);
    let tempInvoice = false;

    if (invoice === undefined) {
      tempInvoice = false;
    } else {
      if (invoice === 0) {
        tempInvoice = false;
      } else {
        tempInvoice = true;
      }
    }

    eventsService
      .countConfirmedActivitiesRes(tempInvoice, searchKey, orgId)
      .then((allActivities) => {
        setTotalItemsCount(allActivities);
      });

    eventsService
      .getConfirmedActivitiesRes(
        start,
        limit,
        tempInvoice,
        searchKey,
        ordine,
        ascendente,
        orgId
      )
      .then((activities) => {
        setActivities(activities);
        setActivitiesLoaded(true);
        setTableLoaded(true);

        //tmp variable used to store the list of activites with only fields needed for the excel files
        var tmpExcelActivites = [];

        activities.forEach((activity) => {
          var tmpActivity = [];

          tmpActivity.nome_attivita = activity?.attivita?.nome;
          tmpActivity.ora_inizio = moment(
            new Date(activity?.datetime_inizio)
          ).format("DD/MM/yyyy, HH:mm");
          tmpActivity.spazio = activity?.spazio.nome;
          tmpActivity.numero_partecipanti = activity?.numero_partecipanti;
          tmpActivity.numero_accompagnatori = activity?.numero_accompagnatori;
          tmpActivity.relatore = activity?.relatori
            ? activity?.relatori?.name + activity?.relatori?.surname
            : "-";
          tmpActivity.intestatario_fatturazione =
            activity?.info_enti?.intestatario_fatturazione;
          tmpActivity.codice_destinatario =
            activity?.info_enti?.codice_destinatario;
          tmpActivity.partita_iva = activity?.info_enti?.partita_iva;
          tmpActivity.codice_fiscale = activity?.info_enti?.codice_fiscale;
          tmpActivity.via = activity?.info_enti?.via;
          tmpActivity.comune = activity?.info_enti?.comune;
          tmpActivity.provincia = activity?.info_enti?.provincia;
          tmpActivity.nazione = activity?.info_enti?.nazione;
          tmpActivity.cap = activity?.info_enti?.cap;
          tmpActivity.indirizzo_fatturazione =
            activity?.info_enti?.indirizzo_fatturazione;
          tmpActivity.cig = activity?.info_enti?.cig;
          tmpActivity.split_payment = activity?.info_enti?.split_payment;
          tmpActivity.pec = activity?.info_enti?.pec;
          tmpActivity.ragione_sociale = activity?.info_enti?.ragione_sociale;
          tmpActivity.numero_fattura = activity?.numero_fattura;
          tmpActivity.fattura_emessa = activity?.fattura_emessa;
          tmpActivity.prezzo = activity?.attivita?.pagamento_ndividuale
            ? activity?.numero_partecipanti * activity?.biglietto?.price
            : activity?.biglietto?.price;

          tmpExcelActivites.push(tmpActivity);
        });

        setExcelActivities(tmpExcelActivites);
      });
  };

  const getAllBookedActivities = (searchKey) => {
    setActivitiesLoaded(false);

    eventsService
      .getConfirmedActivitiesRes(0, -1, undefined, searchKey)
      .then((activities) => {
        //tmp variable used to store the list of activites with only fields needed for the excel files
        var tmpExcelActivites = [];

        activities.forEach((activity) => {
          var tmpActivity = [];

          tmpActivity.nome_attivita = activity?.attivita?.nome;
          tmpActivity.ora_inizio = moment(
            new Date(activity?.datetime_inizio)
          ).format("DD/MM/yyyy, HH:mm");
          tmpActivity.spazio = activity?.spazio.nome;
          tmpActivity.numero_partecipanti = activity?.numero_partecipanti;
          tmpActivity.numero_accompagnatori = activity?.numero_accompagnatori;
          tmpActivity.relatore = activity?.relatori
            ? activity?.relatori.name
            : "-";
          tmpActivity.intestatario_fatturazione =
            activity?.info_enti?.intestatario_fatturazione;
          tmpActivity.codice_destinatario =
            activity?.info_enti?.codice_destinatario;
          tmpActivity.partita_iva = activity?.info_enti?.partita_iva;
          tmpActivity.codice_fiscale = activity?.info_enti?.codice_fiscale;
          tmpActivity.via = activity?.info_enti?.via;
          tmpActivity.comune = activity?.info_enti?.comune;
          tmpActivity.provincia = activity?.info_enti?.provincia;
          tmpActivity.nazione = activity?.info_enti?.nazione;
          tmpActivity.cap = activity?.info_enti?.cap;
          tmpActivity.indirizzo_fatturazione =
            activity?.info_enti?.indirizzo_fatturazione;
          tmpActivity.cig = activity?.info_enti?.cig;
          tmpActivity.split_payment = activity?.info_enti?.split_payment;
          tmpActivity.pec = activity?.info_enti?.pec;
          tmpActivity.ragione_sociale = activity?.info_enti?.ragione_sociale;
          tmpActivity.numero_fattura = activity?.numero_fattura;
          tmpActivity.fattura_emessa = activity?.fattura_emessa;
          tmpActivity.prezzo = activity?.attivita?.pagamento_ndividuale
            ? activity?.numero_partecipanti * activity?.biglietto?.price
            : activity?.biglietto?.price;

          tmpExcelActivites.push(tmpActivity);
        });

        setExcelActivities(tmpExcelActivites);
      });
  };

  const camelCase = (str) => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  const filterColumns = () => {
    const filterColsByKey = [
      "nome_attivita",
      "ora_inizio",
      "spazio",
      "numero_partecipanti",
      "numero_accompagnatori",
      "relatore",
      "ragione_sociale",
      "numero_fattura",
      "prezzo",
    ];

    return filterColsByKey; // OR return columns
  };

  const getLabel = (col) => {
    switch (col) {
      case "nome_attivita":
        return "Attivitá";
      case "ora_inizio":
        return "Data e ora";
      case "spazio":
        return "Spazio";
      case "numero_partecipanti":
        return "Num. Partecipanti";
      case "numero_accompagnatori":
        return "Num. Accompagnatori";
      case "relatore":
        return "Relatore";
      case "intestatario_fatturazione":
        return "Intestatario Fatturazione";
      case "codice_destinatario":
        return "Codice destinatario";
      case "partita_iva":
        return "Partita IVA";
      case "codice_fiscale":
        return "Codice fiscale";
      case "indirizzo_fatturazione":
        return "Indirizzo fatturazione";
      case "split_payment":
        return "Split payment";
      case "ragione_sociale":
        return "Ragione Sociale";
      case "numero_fattura":
        return "Numero Fattura";
      case "prezzo":
        return "Prezzo";
      default:
        return col;
    }
  };

  const markAs = (numero_fattura) => {
    setLoader(true);
    activitiesService
      .updateInvoice(openRecord.id, !openRecord.fattura_emessa, numero_fattura)
      .then(() => {
        setActivePage(1);
        getBookedActivities(
          0,
          itemsPerPage,
          tabIndex,
          searchKey,
          ordine,
          ascendente
        );
        setOpenRecord(null);
        setLoader(false);
      });
  };

  const startingSearch = () => {
    // reset searchKey
    let currentSearchKey = "";
    setSearchKey(currentSearchKey);

    let tempActivePage = 1;
    setActivePage(tempActivePage);

    getBookedActivities(
      itemsPerPage * tempActivePage - itemsPerPage,
      itemsPerPage,
      tabIndex,
      currentSearchKey,
      ordine,
      ascendente
    );
  };

  const toggleGenerateInvoice = () => {
    setShowGenerateInvoice(!showGenerateInvoice);
  };

  useEffect(() => {
    getBookedActivities(
      itemsPerPage * activePage - itemsPerPage,
      itemsPerPage,
      tabIndex,
      searchKey,
      ordine,
      ascendente
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, itemsPerPage]);

  useEffect(() => {
    setActivePage(1);
    getBookedActivities(
      0,
      itemsPerPage,
      tabIndex,
      searchKey,
      ordine,
      ascendente
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex]);

  useEffect(() => {
    const handleSubmit = () => {
      setSearchKey(tempSearchKey);

      let tempActivePage = 1;
      setActivePage(tempActivePage);
      getBookedActivities(
        itemsPerPage * tempActivePage - itemsPerPage,
        itemsPerPage,
        tabIndex,
        tempSearchKey,
        ordine,
        ascendente
      );
      getAllBookedActivities(tempSearchKey);
    };

    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, tempSearchKey]);

  const updateOrder = (ordine, ascendente) => {
    setOrdine(ordine);
    setAscendente(ascendente);
    getBookedActivities(
      itemsPerPage * activePage - itemsPerPage,
      itemsPerPage,
      tabIndex,
      searchKey,
      ordine,
      ascendente
    );
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      overflowX: "scroll",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    rowTop: {
      alignItems: "start",
      color: colors?.primary,
      width: "100%",
    },
    rowBottom: {
      display: "flex",
      justifyContent: "center",
      width: "90%",
      overflow: "scroll",
    },
    subcontainer: {
      width: "100%",
      paddingBottom: 20,
      paddingTop: 20,
      display: "flex",
      flexDirection: "column",
    },
    inputSearch: {
      borderRadius: 40,
      border: "1px solid #F0F0F0",
      width: "50%",
      height: 40,
      display: "flex",
      flexDirection: "row",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
    downloadButton: {
      border: "none",
      outline: "none",
      backgroundColor: colors.darkgray,
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      width: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    goBackButton: {
      width: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    saveButton: {
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: colors.darkgray,
      margin: 30,
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 15,
    },
    arrow: {
      height: 13,
      objectFit: "contain",
    },
    filterButton: {
      border: "1px solid #F0F0F0",
      outline: "none",
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.body}>
        <div style={styles.rowTop}>
          <Tabs
            value={tabIndex}
            variant="fullWidth"
            TabIndicatorProps={{ style: { background: colors?.secondary } }}
            onChange={(event, newValue) => {
              setOpenRecord(null);
              setTabIndex(newValue);
            }}
          >
            <Tab label={dictionary.newInvoices} />
            <Tab label={dictionary.issuedInvoices} />
          </Tabs>
        </div>
        <div style={styles.rowBottom}>
          {!isEmpty(openRecord) ? (
            <div style={styles.subcontainer}>
              <button
                style={styles.goBackButton}
                onClick={() => setOpenRecord(null)}
              >
                <img src={arrow} style={styles.arrow} alt={"Go back"} />
                <p
                  style={{
                    fontSize: 12,
                    color: colors.darkgray,
                    margin: 0,
                    marginLeft: 5,
                    fontWeight: "600",
                  }}
                >
                  Indietro
                </p>
              </button>
              <BillingForm colors={colors} booking={openRecord} />
              {showGenerateInvoice && (
                <GenerateInvoice
                  closeModal={toggleGenerateInvoice}
                  saveData={markAs}
                  colors={colors}
                />
              )}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 40,
                }}
              >
                {loader ? (
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={colors?.primary}
                    height={50}
                    width={50}
                  />
                ) : (
                  <>
                    <button
                      style={
                        tabIndex === 0
                          ? {
                              ...styles.saveButton,
                              backgroundColor: colors.darkgray,
                            }
                          : {
                              ...styles.saveButton,
                              backgroundColor: colors.white,
                              border: "3px solid #303030",
                            }
                      }
                      onClick={() => {
                        if (tabIndex === 0) {
                          setShowGenerateInvoice(true);
                        } else {
                          setShowGenerateInvoice(false);
                          markAs("");
                        }
                      }}
                    >
                      <p
                        style={{
                          fontSize: 12,
                          color: tabIndex === 0 ? colors.white : "#303030",
                          margin: 0,
                          fontWeight: "600",
                        }}
                      >
                        {tabIndex === 0
                          ? dictionary.setIssued
                          : dictionary.setToBeIssued}
                      </p>
                    </button>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div style={styles.subcontainer}>
              <div
                style={{
                  ...styles.row,
                  justifyContent: "space-between",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <div style={styles.inputSearch}>
                  <img
                    alt="Search icon"
                    src={search}
                    style={{ width: 30, objectFit: "contain", margin: 10 }}
                  />
                  <input
                    type="text"
                    style={{
                      border: 0,
                      width: "100%",
                      borderRadius: 40,
                      outline: "none",
                    }}
                    value={tempSearchKey}
                    onChange={(event) => {
                      setTempSearchKey(event.target.value);
                    }}
                    placeholder={dictionary.search}
                  />
                  <button
                    style={{
                      ...styles.filterButton,
                    }}
                    onClick={() => {
                      setTempSearchKey("");
                      setSearchKey("");
                      startingSearch(); //come back to the original result search (i.e. the ones at the page opening)
                    }}
                  >
                    <img
                      alt="Refresh icon"
                      src={refresh}
                      style={{ width: 18, objectFit: "contain", margin: 2 }}
                    />
                  </button>
                </div>
                {totalItemsCount > 0 && (
                  <ExcelFile
                    filename={
                      "Fatture_Attività_" +
                      (tabIndex === 0 ? "Non_Emesse_" : "Emesse_") +
                      moment(new Date()).format("DD/MM/yy")
                    }
                    element={
                      <button style={styles.downloadButton}>
                        <img
                          alt="Download icon"
                          src={download}
                          style={{ width: 15, objectFit: "contain" }}
                        />
                      </button>
                    }
                  >
                    {tabIndex === 0 ? (
                      <ExcelSheet
                        data={excelActivities?.filter(
                          (activity) => activity.fattura_emessa === false
                        )}
                        name={"Da emettere"}
                      >
                        {filterColumns().map((col, key) => {
                          return (
                            <ExcelColumn
                              key={key}
                              label={camelCase(getLabel(col))}
                              value={col}
                            />
                          );
                        })}
                      </ExcelSheet>
                    ) : (
                      <ExcelSheet
                        data={excelActivities?.filter(
                          (activity) => activity.fattura_emessa === true
                        )}
                        name={"Emesse"}
                      >
                        {filterColumns().map((col, key) => {
                          return (
                            <ExcelColumn
                              key={key}
                              label={camelCase(getLabel(col))}
                              value={col}
                            />
                          );
                        })}
                      </ExcelSheet>
                    )}
                  </ExcelFile>
                )}
              </div>
              <div
                id="scroll"
                style={{ overflowY: tableLoaded ? "scroll" : "hidden" }}
              >
                {tableLoaded ? (
                  activities.length > 0 ? (
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <Table
                        tab={tabIndex}
                        activities={activities}
                        colors={colors}
                        setOpenRecord={setOpenRecord}
                        updateOrder={(ordine, ascendente) =>
                          updateOrder(ordine, ascendente)
                        }
                        ordine={ordine}
                        ascendente={ascendente}
                      />
                    </div>
                  ) : activitiesLoaded ? (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 14,
                        color: colors.gray,
                        marginTop: 40,
                      }}
                    >
                      {dictionary.noActivity}
                    </p>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        marginTop: 40,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ReactLoading
                        type={"spinningBubbles"}
                        color={colors?.primary}
                        height={50}
                        width={50}
                      />
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      display: "flex",
                      marginTop: 40,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ReactLoading
                      type={"spinningBubbles"}
                      color={colors?.primary}
                      height={50}
                      width={50}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{ width: "90%" }}>
        {tableLoaded &&
          activities.length > 0 &&
          totalItemsCount > itemsPerPageOptions[0] &&
          isEmpty(openRecord) && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridAutoRows: "1fr",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <select
                  name="options"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: 15,
                    height: 50,
                    width: 70,
                    padding: 10,
                    textAlign: "left",
                    marginRight: 10,
                  }}
                  value={itemsPerPage}
                  onChange={(event) => {
                    setItemsPerPage(parseInt(event.target.value));
                    setActivePage(1);
                  }}
                >
                  {itemsPerPageOptions.map((option, key) => {
                    return (
                      <option key={key} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
                <p style={{ color: colors?.gray, fontSize: 12 }}>
                  {dictionary.pageElements}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => setActivePage(pageNumber)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default InvoicesManager;
