import React, { useContext } from "react";
import { UserContext } from "../contexts";
import moment from "moment";

const Transaction = ({ transaction }) => {
  const colors = useContext(UserContext)?.colors;
  const category = transaction.articolo.categoria_articolo
    ? transaction.articolo.categoria_articolo
    : "-";

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function restoreName(nameString) {
    if (nameString.includes("_DEL")) {
      var splitName = nameString.split("_DEL");
      return splitName[0];
    } else {
      return nameString;
    }
  }

  const styles = {
    field: {
      width: 170,
      fontSize: 15,
    },
    smallerField: {
      width: 140,
      fontSize: 15,
    },
    profileBox: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    menu: {
      border: "1px solid lightgray",
      backgroundColor: colors?.white,
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 10,
      fontSize: 12,
      paddingBottom: 10,
      color: colors?.darkgray,
      borderRadius: 20,
      outline: "none",
      height: 2,
    },
    label: {
      position: "absolute",
      backgroundColor: colors?.quaternary,
      paddingLeft: 15,
      paddingRight: 15,
      borderRadius: 15,
      zIndex: 100,
      color: colors?.darkgray,
    },
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              ...styles.field,
              width: 220,
            }}
          >
            <p>
              {transaction.articolo.nome_articolo
                ? restoreName(transaction.articolo.nome_articolo)
                : "-"}
            </p>
          </div>
        </div>
        <div style={styles.field}>
          <p>
            {transaction.type ? capitalizeFirstLetter(transaction.type) : "-"}
          </p>
        </div>
        <div style={styles.field}>
          <p>
            {transaction.datetime
              ? moment(new Date(transaction.datetime)).format(
                  "DD/MM/yyyy, HH:mm"
                )
              : "-"}
          </p>
        </div>
        <div style={styles.field}>
          <p>{category}</p>
        </div>
        <div style={styles.smallerField}>
          <p>{category !== "Biglietti" ? transaction.previous_stock : "-"}</p>
        </div>
        <div style={styles.smallerField}>
          <p>
            {category !== "Biglietti"
              ? (transaction.later_stock >= transaction.previous_stock
                  ? "+ "
                  : "- ") +
                Math.abs(transaction.later_stock - transaction.previous_stock)
              : "-"}
          </p>
        </div>
        <div style={styles.smallerField}>
          <p>{category !== "Biglietti" ? transaction.later_stock : "-"}</p>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Transaction;
