import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../contexts";
import people from "./../../../img/capacity.png";
import calendar from "./../../../img/calendar.png";
import time from "./../../../img/time.png";
import reserved from "./../../../img/reserved.png";
import close from "./../../../img/closeButton.png";
import invitation from "./../../../img/invitation.png";
import downloadPDF from "./../../../img/pdf.png";
import search from "./../../../img/search.png";
import placeholder from "./../../../img/placeholder_img.png";
import moment from "moment";
import ReservationInfo from "./reservationInfo";
import AutoCompleteText from "../../../components/autocompleteOnline";
import ReactLoading from "react-loading";
import { reservationsService, eventsService } from "../../../_services";
import GetExcel from "../../../components/buttons/getExcel";
import { LanguageContext } from "../../../containers/language";

function spaceFilter(string) {
  let checkedString = string;
  if (checkedString.startsWith(" ")) {
    checkedString = checkedString.substring(1);
  }
  if (checkedString.endsWith(" ")) {
    checkedString = checkedString.substring(0, checkedString.length - 1);
  }
  return checkedString;
}

const EventSideColumn = ({
  history,
  eventSelected,
  idSelected,
  setIdSelected,
  setEventSelected,
  showGenerate,
  setShowGenerate,
  onBooking,
  setOnBooking,
  closeInfoEvent,
  setEventsList,
  setVisitsList,
  toggleDeleteEventWLReservation,
  togglePaymentPopup,
  toggleDeleteEventReservation,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const [height, setHeight] = useState(window.innerHeight);
  // const [ticket, setTicket] = useState("");
  const [counter, setCounter] = useState(1);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [age, setAge] = useState("");
  const [genre, setGenre] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");
  const [card, setCard] = useState("");
  const [errorNumber, setErrorNumber] = useState(false);
  const [childrenList, setChildrenList] = useState([]);
  const [childName, setChildName] = useState("");
  const [childSurname, setChildSurname] = useState("");
  const [childAge, setChildAge] = useState("");
  const [eventResButton, setEventResButton] = useState("PRENOTA");
  const [childForm, setChildForm] = useState(false);
  const [errorAge, setErrorAge] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [excelLoader, setExcelLoader] = useState(false);
  const [excelSelectedEvent, setExcelSelectedEvent] = useState(null);
  const [response, setResponse] = useState({});
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [waitingListFlag, setWaitingListFlag] = useState(false);
  const [tickets, setTickets] = useState([]);
  // const [ticketType, setTicketType] = useState([]);
  const [ticketsNumber, setTicketsNumber] = useState({});

  //Variables used to handle listing of reservations and waiting list using the search bar
  const [inputValue, setInputValue] = useState("");
  const [prenotati, setPrenotati] = useState([]);
  const [listaAttesa, setListaAttesa] = useState([]);

  //For not free events is possibile to have pending waiting list requests. They must be listed together with the actual reservations
  const [pendingList, setPendingList] = useState([]);

  //Boolean used to decide the ordering of reservations
  const [chosenOrder, setChosenOrder] = useState(0);

  const [filters] = useState([
    {
      name: "Ordine Alfabetico",
      id: 0,
    },
    {
      name: "Ordine Cronologico",
      id: 1,
    },
  ]);

  //Used to force re-render of the lists when selecting the ordering
  var [renderCount, setRenderCount] = useState(0);

  const colors = useContext(UserContext)?.colors;

  const getTicketsAvailable = async (mainEventID) => {
    const tickets = await reservationsService.getTicketsAvailable(mainEventID);
    return tickets;
  };

  useEffect(() => {
    setExcelLoader(true);
    eventsService.getAdditionalInfo(eventSelected.id).then((result) => {
      setExcelSelectedEvent(result);
      setExcelLoader(false);
    });

    var tmpPrenotati = [];
    var tmpAttesa = [];
    var tmpPending = [];

    if (inputValue !== "") {
      tmpPrenotati = eventSelected.prenotazioni_evento?.filter((prenotato) => {
        return (
          prenotato.nome_partecipante
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
          prenotato.cognome_partecipante
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
          prenotato.nome_partecipante
            .concat(" ".concat(prenotato.cognome_partecipante))
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
          prenotato.cognome_partecipante
            .concat(" ".concat(prenotato.nome_partecipante))
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        );
      });

      tmpAttesa = eventSelected.lista_attesa?.filter((attesa) => {
        return (
          (attesa.nome_partecipante
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
            attesa.cognome_partecipante
              .toLowerCase()
              .includes(inputValue.toLowerCase()) ||
            attesa.nome_partecipante
              .concat(" ".concat(attesa.cognome_partecipante))
              .toLowerCase()
              .includes(inputValue.toLowerCase()) ||
            attesa.cognome_partecipante
              .concat(" ".concat(attesa.nome_partecipante))
              .toLowerCase()
              .includes(inputValue.toLowerCase())) &&
          attesa.stato !== "pending"
        );
      });

      tmpPending = eventSelected.lista_attesa?.filter((attesa) => {
        return (
          (attesa.nome_partecipante
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
            attesa.cognome_partecipante
              .toLowerCase()
              .includes(inputValue.toLowerCase()) ||
            attesa.nome_partecipante
              .concat(" ".concat(attesa.cognome_partecipante))
              .toLowerCase()
              .includes(inputValue.toLowerCase()) ||
            attesa.cognome_partecipante
              .concat(" ".concat(attesa.nome_partecipante))
              .toLowerCase()
              .includes(inputValue.toLowerCase())) &&
          attesa.stato === "pending"
        );
      });
    } else {
      tmpPrenotati = eventSelected.prenotazioni_evento;
      tmpAttesa = eventSelected.lista_attesa?.filter((attesa) => {
        return attesa.stato !== "pending";
      });
      tmpPending = eventSelected.lista_attesa?.filter((attesa) => {
        return attesa.stato === "pending";
      });
    }

    if (chosenOrder === 0) {
      tmpPrenotati.sort((a, b) =>
        a.cognome_partecipante.localeCompare(b.cognome_partecipante)
      );
      tmpAttesa.sort((a, b) =>
        a.cognome_partecipante.localeCompare(b.cognome_partecipante)
      );
      tmpPending.sort((a, b) =>
        a.cognome_partecipante.localeCompare(b.cognome_partecipante)
      );
    } else {
      tmpPrenotati.sort(function (a, b) {
        return parseInt(a.id) - parseInt(b.id);
      });
      tmpAttesa.sort(function (a, b) {
        return parseInt(a.id) - parseInt(b.id);
      });
      tmpPending.sort(function (a, b) {
        return parseInt(a.id) - parseInt(b.id);
      });
    }

    setPrenotati(tmpPrenotati);
    setListaAttesa(tmpAttesa);
    setPendingList(tmpPending);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventSelected, inputValue]);

  useEffect(() => {
    if (renderCount > 0) {
      var prenotatiSorted = [];
      var listaSorted = [];

      if (chosenOrder === 0) {
        prenotatiSorted = [...prenotati].sort((a, b) =>
          a.cognome_partecipante.localeCompare(b.cognome_partecipante)
        );
        listaSorted = [...listaAttesa].sort((a, b) =>
          a.cognome_partecipante.localeCompare(b.cognome_partecipante)
        );
      } else {
        prenotatiSorted = [...prenotati].sort(function (a, b) {
          return parseInt(a.id) - parseInt(b.id);
        });
        listaSorted = [...listaAttesa].sort(function (a, b) {
          return parseInt(a.id) - parseInt(b.id);
        });
      }

      setPrenotati(prenotatiSorted);
      setListaAttesa(listaSorted);
    } else {
      setRenderCount(renderCount + 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenOrder]);

  const styles = {
    infoBox: {
      width: 600,
      height: "100%",
      overflowY: "scroll",
      backgroundColor: colors?.tertiary,
    },
    header: {
      height: 100,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    backButton: {
      position: "absolute",
      top: 15,
      right: 15,
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      alignSelf: "left",
      zIndex: 100,
      cursor: "pointer",
    },
    titleBox: {
      width: "100%",
      backgroundColor: colors?.secondary,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      color: colors?.white,
      fontWeight: "700",
      fontSize: 20,
    },
    subtitle: {
      width: "100%",
      backgroundColor: colors?.primary,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      marginTop: 3,
    },
    fieldBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    field: {
      fontSize: 15,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    location: {
      color: colors?.white,
      fontSize: 11,
    },
    row: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
    },
    label: {
      color: colors?.secondary,
      width: "80%",
      fontSize: 13,
      textAlign: "left",
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      cursor: "pointer",
    },
    textInput: {
      borderRadius: 5,
      border: 0,
      outline: "none",
      textAlign: "center",
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
      width: "80%",
    },
    errorMessage: {
      color: colors?.highlight,
      fontSize: 10,
      fontWeight: "bold",
      marginTop: -6,
    },
    warningMessage: {
      color: colors?.highlight,
      fontSize: 15,
      fontWeight: "bold",
      marginTop: 0,
    },
    list: {
      width: "100%",
      height: "calc(100vh - 530px)",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      overflowY: "scroll",
    },
    body: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderBottomLeftRadius: 30,
      borderBottomRightRadius: 30,
    },
    menu: {
      border: "transparent",
      height: "20px",
      paddingRight: 5,
      paddingLeft: 5,
      marginRight: 15,
      color: colors.darkgray,
      borderRadius: 20,
      backgroundColor: "rgba(256, 256, 256, 0.3)",
      outline: "none",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-end",
      fontSize: "10px",
    },
    inputSearch: {
      borderRadius: 40,
      border: "1px solid #F0F0F0",
      width: "90%",
      height: 30,
      marginTop: 5,
      marginBottom: 0,
      display: "flex",
      flexDirection: "row",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
      backgroundColor: colors?.white,
    },
  };

  const toggleBooking = () => {
    getTicketsAvailable(eventSelected.id_main_event).then((r) => {
      setTickets(r);
    });
    setOnBooking(!onBooking);
    //setTicket(eventSelected.biglietti_eventi[0]);
    setName("");
    setSurname("");
    setEmail("");
    setMobile("");
    setNumber("");
    setChildName("");
    setChildSurname("");
    setChildAge("");
    setChildrenList([]);
    setChildForm(false);
    setEventResButton(
      eventSelected?.flag_bambini
        ? "AVANTI"
        : eventSelected?.posti_disponibili > 0
        ? "PRENOTA"
        : "AGGIUNGI IN LISTA D'ATTESA"
    );
  };

  const reset = () => {
    //setTicket(eventSelected.biglietti_eventi[0]);
    setName("");
    setSurname("");
    setNumber("");
    setEmail("");
    setMobile("");
    setChildrenList([]);
    setZip("");
    setCountry("");
    setGenre("");
    setAge("");
    setCard("");
    setTicketsNumber({});
    setErrorNumber(false);
  };

  const createEventReservation = () => {
    const ticketsNumberKey = Object.keys(ticketsNumber);
    const ticketsNumberValue = Object.values(ticketsNumber);
    let howManyGoodExpected = 0;
    ticketsNumberValue.forEach((tv) => {
      if (tv.number && tv.number > 0) {
        howManyGoodExpected++;
      }
    });

    function processTicket(index) {
      if (
        ticketsNumberValue[index] &&
        ticketsNumberValue[index].number &&
        ticketsNumberValue[index].number > 0
      ) {
        reservationsService
          .createEventReservation(
            eventSelected.id,
            card,
            spaceFilter(name),
            spaceFilter(surname),
            parseInt(ticketsNumberValue[index].number),
            spaceFilter(email.toLowerCase()),
            mobile,
            childrenList,
            zip,
            country,
            genre,
            age,
            eventSelected?.posti_disponibili > 0 ? false : true,
            ticketsNumberKey[index],
            parseFloat(ticketsNumberValue[index].price) *
              parseInt(ticketsNumberValue[index].number)
          )
          .then((response) => {
            howManyGoodExpected--;
            if (howManyGoodExpected === 0) {
              setResponse(response);
              setShowResponseMessage(true);
              setLoader(false);

              setTimeout(() => setShowResponseMessage(false), 5000);
              if (response.status === true) {
                let temp = response.message;
                for (let i = 0; i < temp.length; i++) {
                  temp[i].ora_inizio = new Date(temp[i].ora_inizio);
                  temp[i].ora_fine = new Date(temp[i].ora_fine);
                }
                setOnBooking(false);
                setEventsList(temp);
                setEventSelected(
                  temp.filter((e) => e.id === eventSelected.id)[0]
                );
                setChildrenList([]);
                setCounter(1);
                reset();
              }
            }
            // Process the next ticket
            if (index + 1 < ticketsNumberKey.length) {
              processTicket(index + 1);
            }
          })
          .catch((errors) => {
            if (errors.statusCode === 401 || errors.statusCode === 403) {
              history.push("/");
            }
          });
      } else {
        // Process the next ticket
        if (index + 1 < ticketsNumberKey.length) {
          processTicket(index + 1);
        }
      }
    }

    if (ticketsNumberKey.length > 0) {
      processTicket(0);
    } else {
      reservationsService
        .createEventReservation(
          eventSelected.id,
          card,
          spaceFilter(name),
          spaceFilter(surname),
          parseInt(number),
          spaceFilter(email.toLowerCase()),
          mobile,
          childrenList,
          zip,
          country,
          genre,
          age,
          eventSelected?.posti_disponibili > 0 ? false : true
        )
        .then((response) => {
          setResponse(response);
          setShowResponseMessage(true);
          setLoader(false);

          setTimeout(() => setShowResponseMessage(false), 5000);
          if (response.status === true) {
            let temp = response.message;
            for (let i = 0; i < temp.length; i++) {
              temp[i].ora_inizio = new Date(temp[i].ora_inizio);
              temp[i].ora_fine = new Date(temp[i].ora_fine);
            }
            setOnBooking(false);
            setEventsList(temp);
            setEventSelected(temp.filter((e) => e.id === eventSelected.id)[0]);
            setChildrenList([]);
            setCounter(1);
            reset();
          }
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            history.push("/");
          }
        });
    }
  };

  const modifyReservation = (id, eventId, newNumber) => {
    reservationsService
      .modifyReservation(id, newNumber)
      .then((response) => {
        let temp = response;
        for (let i = 0; i < temp.length; i++) {
          temp[i].ora_inizio = new Date(temp[i].ora_inizio);
          temp[i].ora_fine = new Date(temp[i].ora_fine);
        }
        if (temp[0].flag_visita) {
          setVisitsList(temp);
        } else {
          setEventsList(temp);
          setEventSelected(temp.filter((e) => e.id === eventId)[0]);
        }
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          history.push("/");
        }
      });
  };

  const preventChildAdd = () => {
    return childName === "" || childSurname === "" || childAge === "";
  };

  const preventEventReservation = () => {
    return (
      name === "" ||
      surname === "" ||
      number === "" ||
      mobile === "" ||
      email === ""
    );
  };

  const resetChildForm = () => {
    setChildName("");
    setChildSurname("");
    setChildAge("");
  };
  const childrenEventInfoCheck = () => {
    let temp = childrenList;

    if (!preventChildAdd()) {
      let childInfo = {
        nome_bambino: childName,
        cognome_bambino: childSurname,
        eta_bambino: parseInt(childAge),
      };
      temp.push(childInfo);
      setChildrenList(temp);
      resetChildForm();
      setCounter(childrenList.length + 1);
    }
    if (temp.length === parseInt(number) - 1) {
      setEventResButton("PRENOTA");
    } else {
      setEventResButton("AVANTI");
    }
    if (eventSelected.flag_bambini && temp.length !== parseInt(number)) {
      setChildForm(true);
    } else {
      setChildForm(false);
    }
    return eventSelected.flag_bambini && temp.length !== parseInt(number);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let error = re.test(String(email).toLowerCase());
    if (email === "") {
      setShowError(false);
    }
    setIsEmail(error);
    return error;
  };

  const onDownloadRes = (id) => {
    setLoader(true);
    reservationsService
      .getResDoc(id)
      .then((file) => {
        setLoader(false);
        var a = document.createElement("a");
        a.href = file.url;
        a.target = "_blank";
        a.download = "prenotazioni.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          history.push("/");
        }
      });
  };

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newHeight = window.innerHeight;
      setHeight(newHeight);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <div id="scroll" style={styles.infoBox}>
      <div style={styles.header}>
        <button style={styles.backButton} onClick={closeInfoEvent}>
          <img
            alt="Back Button"
            src={close}
            style={{ height: 20, objectFit: "cover" }}
          />
        </button>
        <img
          alt="Header"
          src={eventSelected.immagine_evento.url || placeholder}
          style={{ height: "100%", width: "100%", objectFit: "cover" }}
        />
      </div>
      <div style={styles.titleBox}>
        <p style={styles.title}>{eventSelected.nome_evento}</p>
      </div>
      <div style={styles.subtitle}>
        <div style={styles.fieldBox}>
          <img
            alt="Date"
            src={calendar}
            style={{ height: 15, marginRight: 10 }}
          />
          <p style={styles.location}>
            {moment(eventSelected.ora_inizio).format("D MMMM")}
          </p>
        </div>
        <div style={styles.fieldBox}>
          <img alt="Time" src={time} style={{ height: 12, marginRight: 10 }} />
          <p style={styles.location}>
            {moment(eventSelected.ora_inizio).format("HH:mm") +
              " - " +
              moment(eventSelected.ora_fine).format("HH:mm")}
          </p>
        </div>
      </div>
      <div style={styles.subtitle}>
        <div style={styles.fieldBox}>
          <img
            alt="Reserved"
            src={reserved}
            style={{ height: 12, marginRight: 10 }}
          />
          <p style={styles.location}>
            {eventSelected.posti_prenotati !== null
              ? eventSelected.posti_prenotati
              : eventSelected.numero_partecipanti}
          </p>
          <p style={{ ...styles.location, marginLeft: 5 }}> prenotati</p>
        </div>
        {eventSelected.posti_totali && (
          <div style={styles.fieldBox}>
            <img
              alt="Capacity"
              src={people}
              style={{ height: 12, marginRight: 10 }}
            />
            <p style={styles.location}>{eventSelected.posti_totali}</p>
            <p style={{ ...styles.location, marginLeft: 5 }}> posti</p>
          </div>
        )}
      </div>
      <div style={{ ...styles.fieldBox, marginTop: 20 }}>
        {new Date(eventSelected.ora_inizio) >= new Date() && (
          <button
            style={{ ...styles.button, color: colors?.white }}
            onClick={() => setShowGenerate(!showGenerate)}
          >
            <img
              alt="Invitation icon"
              src={invitation}
              style={{ height: 15, objectFit: "contain" }}
            />
          </button>
        )}
        {eventSelected.prenotazioni_evento?.length > 0 && (
          <button
            style={{ ...styles.button, color: colors?.white }}
            onClick={() => onDownloadRes(eventSelected.id)}
          >
            <img
              alt="Download PDF"
              src={downloadPDF}
              style={{ height: 20, objectFit: "contain" }}
            />
          </button>
        )}
        {eventSelected.prenotazioni_evento?.length > 0 &&
          (excelLoader ? (
            <ReactLoading
              type={"spinningBubbles"}
              color={colors?.secondary}
              height={15}
              width={15}
            />
          ) : (
            excelSelectedEvent && (
              <GetExcel event={excelSelectedEvent}></GetExcel>
            )
          ))}
      </div>
      <div style={styles.body}>
        <div style={{ width: "100%" }}>
          {!onBooking &&
          eventSelected?.prenotazioni_evento?.length > 0 &&
          eventSelected?.prenotazioni_evento
            .map((pren) => pren.numero_partecipanti)
            .reduce((prev, next) => prev + next) >=
            eventSelected.posti_totali ? (
            <>
              <p
                style={{
                  ...styles.button,
                  width: "100%",
                  backgroundColor: colors?.secondary,
                  color: colors?.white,
                  paddingTop: 10,
                  paddingBottom: 10,
                  textAlign: "center",
                }}
              >
                POSTI TERMINATI
              </p>
              <button
                style={{
                  ...styles.button,
                  width: 200,
                  backgroundColor: colors?.secondary,
                  color: colors?.white,
                  padding: 10,
                  borderRadius: 15,
                  margin: 20,
                }}
                onClick={toggleBooking}
              >
                AGGIUNGI IN LISTA D'ATTESA
              </button>
            </>
          ) : (
            !onBooking && (
              <button
                style={{
                  ...styles.button,
                  width: 200,
                  backgroundColor: colors?.secondary,
                  color: colors?.white,
                  padding: 10,
                  borderRadius: 15,
                  margin: 20,
                }}
                onClick={toggleBooking}
              >
                PRENOTA
              </button>
            )
          )}
          {onBooking ? (
            <>
              <button
                style={{
                  ...styles.button,
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                onClick={toggleBooking}
              >
                <img
                  alt="Close Button"
                  src={close}
                  style={{ height: 15, objectFit: "contain", margin: 20 }}
                />
              </button>
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  overflowY: "scroll",
                  height: height - 450,
                }}
              >
                {eventSelected.ora_inizio >= new Date() ? (
                  <></>
                ) : eventSelected.ora_fine <= new Date() ? (
                  <p style={styles.warningMessage}>
                    Attenzione: evento già concluso
                  </p>
                ) : (
                  <p style={styles.warningMessage}>
                    Attenzione: evento in corso
                  </p>
                )}
                {childForm && (
                  <p
                    style={{
                      margin: 0,
                      width: "80%",
                      textAlign: "left",
                      fontSize: 12,
                      color: colors?.darkgray,
                      marginBottom: 20,
                    }}
                  >
                    {counter}/{number}
                  </p>
                )}
                <label style={styles.label}>
                  Nome {childForm && " bambino"}
                </label>
                <input
                  type="text"
                  name="name"
                  value={childForm ? childName : name}
                  onChange={(event) => {
                    if (childForm) {
                      setChildName(event.target.value);
                    } else {
                      setName(event.target.value);
                    }
                  }}
                  placeholder="Nome"
                  style={{ ...styles.textInput }}
                />
                <label style={styles.label}>
                  Cognome {childForm && " bambino"}
                </label>
                <input
                  type="text"
                  name="surname"
                  value={childForm ? childSurname : surname}
                  onChange={(event) => {
                    if (childForm) {
                      setChildSurname(event.target.value);
                    } else {
                      setSurname(event.target.value);
                    }
                  }}
                  placeholder="Cognome"
                  style={{ ...styles.textInput }}
                />
                {childForm && (
                  <>
                    <label style={styles.label}>
                      Età {childForm && " bambino"}
                    </label>
                    <input
                      type="text"
                      name="surname"
                      value={childAge}
                      onChange={(event) => {
                        if (
                          (parseInt(event.target.value) > 0 &&
                            parseInt(event.target.value) <= 18) ||
                          event.target.value === ""
                        ) {
                          setChildAge(event.target.value.replace(/[^\d]/, ""));
                          setErrorAge(false);
                        } else {
                          setChildAge("");
                          setErrorAge(true);
                        }
                      }}
                      placeholder="Età"
                      style={{ ...styles.textInput }}
                    />
                  </>
                )}
                {errorAge && (
                  <p style={styles.errorMessage}>
                    L'età di un bambino non può essere superiore a 18 anni, ne'
                    inferiore a 1.
                  </p>
                )}

                {!childForm && (
                  <>
                    <label style={styles.label}>Email</label>
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                        validateEmail(event.target.value);
                      }}
                      placeholder="Email"
                      style={{ ...styles.textInput }}
                    />
                    {showError && !isEmail && (
                      <p
                        style={{
                          color: colors?.highlight,
                          marginTop: 2,
                          fontSize: 10,
                          width: "72%",
                        }}
                      >
                        Email non valida
                      </p>
                    )}

                    <label style={styles.label}>Telefono</label>
                    <input
                      type="tel"
                      name="mobile"
                      value={mobile}
                      onChange={(event) =>
                        setMobile(event.target.value.replace(/[^\d]/, ""))
                      }
                      placeholder="Cellulare"
                      style={{ ...styles.textInput }}
                    />
                  </>
                )}
                <hr />
                {(tickets && tickets.length) > 0 ? (
                  <>
                    <div style={{ width: "100%" }}>
                      {tickets.map((t) => (
                        <div key={`ticket-type-numer-${t.id}`}>
                          <label
                            style={Object.assign({}, styles.label, {
                              display: "block",
                              padding: "10px 15px 0",
                            })}
                          >
                            Numero biglietti {t.nome}
                          </label>
                          <input
                            type="number"
                            name="number"
                            min={0}
                            // value={number}
                            value={
                              ticketsNumber[t.id] && ticketsNumber[t.id].number
                            }
                            onChange={(event) => {
                              const newTicketsTypeNumber = Object.assign(
                                {},
                                ticketsNumber
                              );
                              if (
                                !newTicketsTypeNumber[t.id] ||
                                newTicketsTypeNumber[t.id].number
                              ) {
                                newTicketsTypeNumber[t.id] = {};
                              }
                              newTicketsTypeNumber[t.id].number =
                                event.target.value;
                              newTicketsTypeNumber[t.id].price = t.prezzo;
                              setTicketsNumber(newTicketsTypeNumber);
                              const valuesTicketsNumber =
                                Object.values(newTicketsTypeNumber);
                              let newNumber = 0;
                              valuesTicketsNumber.forEach((v) => {
                                newNumber += Number(v.number);
                              });

                              if (eventSelected.posti_disponibili > 0) {
                                if (
                                  parseInt(newNumber) < 1 ||
                                  eventSelected.posti_prenotati +
                                    parseInt(newNumber) >
                                    eventSelected.posti_totali
                                ) {
                                  setErrorNumber(true);
                                  setNumber(newNumber);
                                } else {
                                  setErrorNumber(false);
                                  setNumber(newNumber);
                                }
                              } else {
                                setErrorNumber(false);
                                setNumber(newNumber);
                              }
                            }}
                            placeholder={`N. ${t.nome}`}
                            style={{ ...styles.textInput }}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <label style={styles.label}>Numero partecipanti</label>
                    <input
                      type="number"
                      name="number"
                      min={1}
                      value={number}
                      onChange={(event) => {
                        if (eventSelected.posti_disponibili > 0) {
                          if (
                            parseInt(event.target.value) < 1 ||
                            eventSelected.posti_prenotati +
                              parseInt(event.target.value) >
                              eventSelected.posti_totali
                          ) {
                            setErrorNumber(true);
                            setNumber(event.target.value);
                          } else {
                            setErrorNumber(false);
                            setNumber(event.target.value);
                          }
                        } else {
                          setErrorNumber(false);
                          setNumber(event.target.value);
                        }
                      }}
                      placeholder="Numero partecipanti"
                      style={{ ...styles.textInput }}
                    />

                    {errorNumber && (
                      <p
                        style={Object.assign({}, styles.errorMessage, {
                          marginTop: "15px",
                        })}
                      >
                        Solo{" "}
                        {eventSelected.posti_totali -
                          eventSelected.posti_prenotati}{" "}
                        posti disponibili. La prenotazione verrà messa in lista
                        d'attesa.
                      </p>
                    )}
                  </>
                )}

                <hr />
                <label style={styles.label}>Numero Card</label>
                <input
                  type="text"
                  name="card"
                  value={card}
                  onChange={(event) => {
                    setCard(event.target.value.toLowerCase());
                  }}
                  placeholder="N° Card"
                  style={{ ...styles.textInput }}
                />
                <p style={styles.label}>Città</p>
                <AutoCompleteText
                  placeholder="Città"
                  apiKey="GHCZpfub3aa7Io8V9v9x08SzKW1Q9Y5_JX_6yCOGBX8"
                  setZip={(zip) => setZip(zip)}
                  setCountry={(country) => setCountry(country)}
                  colors={colors}
                />
                <p style={styles.label}>Anno di nascita</p>
                <input
                  value={age}
                  maxLength={4}
                  onChange={(event) => {
                    if (event.target.value.length === 4) {
                      if (
                        (parseInt(event.target.value) >=
                          new Date().getFullYear() - 120 &&
                          parseInt(event.target.value) <=
                            new Date().getFullYear()) ||
                        event.target.value === ""
                      ) {
                        setAge(event.target.value.replace(/[^0-9.]/, ""));
                      } else {
                        setAge("");
                      }
                    } else {
                      setAge(event.target.value.replace(/[^0-9.]/, ""));
                    }
                  }}
                  placeholder="Es. 1990"
                  style={styles.textInput}
                />
                <p style={styles.label}>Genere</p>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <label
                    style={{
                      marginRight: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <input
                      type="radio"
                      style={{ outline: "none", marginRight: 10 }}
                      value={"M"}
                      checked={genre === "M"}
                      onChange={(event) => setGenre(event.target.value)}
                    />
                    <p>M</p>
                  </label>
                  <label
                    style={{
                      marginRight: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <input
                      type="radio"
                      style={{ outline: "none", marginRight: 10 }}
                      value={"F"}
                      checked={genre === "F"}
                      onChange={(event) => setGenre(event.target.value)}
                    />
                    <p>F</p>
                  </label>
                  <label
                    style={{
                      marginRight: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <input
                      type="radio"
                      style={{ outline: "none", marginRight: 10 }}
                      value={"Altro"}
                      checked={genre === "Altro"}
                      onChange={(event) => setGenre(event.target.value)}
                    />
                    <p>Altro</p>
                  </label>
                </div>
              </form>
              {loader ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 30,
                  }}
                >
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={colors?.primary}
                    height={50}
                    width={50}
                  />
                </div>
              ) : (
                <button
                  style={{
                    ...styles.button,
                    width: 200,
                    backgroundColor: colors?.secondary,
                    color: colors?.white,
                    padding: 10,
                    borderRadius: 15,
                    margin: 30,
                    opacity: (
                      childForm ? preventChildAdd() : preventEventReservation()
                    )
                      ? 0.5
                      : 1,
                  }}
                  onClick={() => {
                    let flag = childrenEventInfoCheck();
                    if (!flag) {
                      setLoader(true);
                      createEventReservation();
                    }
                  }}
                  disabled={
                    childForm ? preventChildAdd() : preventEventReservation()
                  }
                >
                  {eventResButton}
                </button>
              )}
              {showResponseMessage && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    backgroundColor: response.status
                      ? colors?.secondary
                      : colors?.highlight,
                    width: "100%",
                    paddingTop: 30,
                    paddingBottom: 30,
                  }}
                >
                  <p
                    style={{
                      fontWeight: "700",
                      color: colors?.white,
                      fontSize: 18,
                    }}
                  >
                    {response.status
                      ? "Prenotazione effettuata"
                      : "Errore durante la prenotazione"}
                  </p>
                  {response.status === false && (
                    <p
                      style={{
                        color: colors?.white,
                        fontSize: 15,
                      }}
                    >
                      {response.message}
                    </p>
                  )}
                </div>
              )}
            </>
          ) : eventSelected?.prenotazioni_evento?.length > 0 ||
            eventSelected?.lista_attesa?.length > 0 ? (
            <div style={styles.field}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p
                    style={{
                      color: waitingListFlag
                        ? colors?.lightgray
                        : colors?.primary,
                      fontSize: 13,
                      textAlign: "left",
                      marginLeft: 15,
                      marginRight: 15,
                      cursor: "pointer",
                    }}
                    onClick={() => setWaitingListFlag(false)}
                  >
                    Prenotati
                  </p>
                  <p
                    style={{
                      color: waitingListFlag
                        ? colors?.primary
                        : colors?.lightgray,
                      fontSize: 13,
                      textAlign: "left",
                      marginRight: 15,
                      cursor: "pointer",
                    }}
                    onClick={() => setWaitingListFlag(true)}
                  >
                    Lista d'attesa (
                    {eventSelected.lista_attesa ? eventSelected.tot_attesa : 0})
                  </p>
                </div>
                <select
                  style={styles.menu}
                  name={chosenOrder?.name}
                  value={chosenOrder?.id}
                  onChange={(event) => {
                    setChosenOrder(filters[event.target.selectedIndex].id);
                  }}
                >
                  {filters.map((type, key) => (
                    <option key={key} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>
              <div style={styles.inputSearch}>
                <img
                  alt="Search icon"
                  src={search}
                  style={{
                    width: 30,
                    objectFit: "contain",
                    margin: 10,
                    color: colors.white,
                  }}
                />
                <input
                  type="text"
                  style={{
                    border: 0,
                    width: "100%",
                    borderRadius: 40,
                    outline: "none",
                  }}
                  value={inputValue}
                  onChange={(name) => setInputValue(name.target.value)}
                  placeholder={dictionary.searchReservation}
                />
              </div>
              <div style={styles.list}>
                <>
                  {waitingListFlag
                    ? listaAttesa.map((e, key) => {
                        return (
                          <ReservationInfo
                            key={key}
                            reservation={e}
                            idSelected={idSelected}
                            setIdSelected={setIdSelected}
                            eventSelected={eventSelected}
                            onModifyReservation={modifyReservation}
                            onDeleteReservation={toggleDeleteEventReservation}
                            togglePaymentPopup={togglePaymentPopup}
                            onDeleteWLReservation={
                              toggleDeleteEventWLReservation
                            }
                            isWaitingList={true}
                          />
                        );
                      })
                    : prenotati.map((e, key) => {
                        return (
                          <ReservationInfo
                            key={key}
                            reservation={e}
                            idSelected={idSelected}
                            setIdSelected={setIdSelected}
                            eventSelected={eventSelected}
                            onModifyReservation={modifyReservation}
                            onDeleteReservation={toggleDeleteEventReservation}
                            togglePaymentPopup={togglePaymentPopup}
                            onDeleteWLReservation={
                              toggleDeleteEventWLReservation
                            }
                            isWaitingList={false}
                          />
                        );
                      })}
                </>
                <>
                  {!waitingListFlag &&
                    pendingList !== undefined &&
                    pendingList.length > 0 && (
                      <p
                        style={{
                          color: waitingListFlag
                            ? colors?.lightgray
                            : colors?.primary,
                          fontSize: 13,
                          textAlign: "left",
                          marginLeft: 15,
                          marginRight: 15,
                        }}
                      >
                        In attesa di pagamento
                      </p>
                    )}
                  {!waitingListFlag &&
                    pendingList !== undefined &&
                    pendingList.length > 0 &&
                    pendingList.map((e, key) => {
                      return (
                        <ReservationInfo
                          key={key}
                          reservation={e}
                          idSelected={idSelected}
                          setIdSelected={setIdSelected}
                          eventSelected={eventSelected}
                          onModifyReservation={modifyReservation}
                          onDeleteReservation={toggleDeleteEventReservation}
                          togglePaymentPopup={togglePaymentPopup}
                          onDeleteWLReservation={toggleDeleteEventWLReservation}
                          isWaitingList={true}
                        />
                      );
                    })}
                </>
              </div>
              {showResponseMessage && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    backgroundColor: response.status
                      ? colors?.secondary
                      : colors?.highlight,
                    width: "100%",
                    paddingTop: 30,
                    paddingBottom: 30,
                  }}
                >
                  <p
                    style={{
                      fontWeight: "700",
                      color: colors?.white,
                      fontSize: 18,
                    }}
                  >
                    {response.status
                      ? "Prenotazione effettuata"
                      : "Errore durante la prenotazione"}
                  </p>
                  {response.status === false && (
                    <p
                      style={{
                        color: colors?.white,
                        fontSize: 15,
                      }}
                    >
                      {response.message}
                    </p>
                  )}
                </div>
              )}
            </div>
          ) : (
            eventSelected?.attivita === undefined && (
              <p
                style={{
                  color: colors?.secondary,
                  fontSize: 13,
                }}
              >
                Nessuna prenotazione.
              </p>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default EventSideColumn;
