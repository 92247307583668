import React, { useState, useEffect, useContext, useRef } from "react";
import {
  eventsService,
  settingsService,
  activitiesService,
  customersService,
} from "../../../_services";
import logo from "./../../../img/logo_white.png";
import user from "./../../../img/user_secondary.png";
import ReactLoading from "react-loading";
import back from "./../../../img/arrow_back.png";
import { UserContext } from "./../../../contexts";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import remove from "./../../../img/closeButton.png";
import checked from "./../../../img/reserved.png";
import errorIcon from "./../../../img/error.png";
import Field from "../../../components/field";
const localizer = momentLocalizer(moment);

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function getSpeakersForActivity(availabilities) {
  let speakers = [];
  availabilities.forEach((av) => {
    if (!speakers.some((sp) => sp.id === av.relatori.id)) {
      speakers.push(av.relatori);
    }
  });
  return speakers;
}

function generateBody(
  orgId,
  id,
  availability,
  startTime,
  defaultProps,
  customProps,
  billingProps,
  speaker,
  ticketSelected,
  operators,
  location,
  settings,
  pagamento_individuale,
  total
) {
  var obj = "{";
  obj = obj + '"Org":' + orgId + ",";
  obj = obj + '"Attività":' + id + ",";
  obj = obj + '"disponibilita_relatori":' + availability + ",";
  obj = obj + '"Data e ora":"' + startTime + '",';
  for (let i = 0; i < defaultProps.length; i++) {
    obj =
      obj +
      '"' +
      defaultProps[i].name +
      '":"' +
      (defaultProps[i].value ? defaultProps[i].value.replace(/"/g, "") : "") +
      '",';
  }
  obj = obj + '"Operatore":[';
  let opList = [];
  for (let i = 0; i < operators?.length; i++) {
    opList.push(operators[i].id);
  }
  obj = obj + opList + "],";
  obj = obj + '"Spazio":"' + location + '",';
  obj = obj + '"relatori": ' + speaker + ",";
  obj = obj + '"ente": {';
  for (let i = 0; i < billingProps.length; i++) {
    if (billingProps[i].name === "Nome e cognome")
      billingProps[i].name = "Ragione sociale";
    obj =
      obj +
      '"' +
      billingProps[i].name +
      '":"' +
      (billingProps[i].value ? billingProps[i].value.replace(/"/g, "") : "") +
      '"';
    if (i < billingProps.length - 1) {
      obj = obj + ",";
    }
  }
  obj = obj + "},";
  obj = obj + '"biglietto": ' + JSON.stringify(ticketSelected) + ",";
  obj = obj + '"valori": ' + JSON.stringify(customProps) + ",";
  obj = obj + '"info_pagamento": ' + JSON.stringify(settings) + ",";
  obj =
    obj +
    '"pagamento_individuale": ' +
    JSON.stringify(pagamento_individuale) +
    ",";
  obj = obj + '"totale": ' + JSON.stringify(total);
  obj = obj + "}";

  return JSON.parse(obj);
}

function getTimeSlots(start, end) {
  var startTime = moment(start, "HH:mm");
  var endTime = moment(end, "HH:mm");

  if (endTime.isBefore(startTime)) {
    endTime.add(1, "day");
  }

  var timeSlots = [];

  while (startTime <= endTime) {
    timeSlots.push(new moment(startTime).format("HH:mm"));
    startTime.add(30, "minutes");
  }
  return timeSlots;
}

function shadeColor(color, percent) {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R / 10) * 10;
  G = Math.round(G / 10) * 10;
  B = Math.round(B / 10) * 10;

  var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

function checkColor(color) {
  if (color !== undefined) {
    var c = color.substring(1); // strip #
    var rgb = parseInt(c, 16); // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff; // extract red
    var g = (rgb >> 8) & 0xff; // extract green
    var b = (rgb >> 0) & 0xff; // extract blue
    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    if (luma < 80) {
      return "white";
    } else {
      return "#171515";
    }
  } else {
    return "white";
  }
}

const ActivityView = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [info, setInfo] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [activity, setActivity] = useState(null);
  const [speakers, setSpeakers] = useState([]);
  const [dateSelected, setDateSelected] = useState(new Date());
  const [availabilitySelected, setAvailabilitySelected] = useState(null);
  const [timeSelected, setTimeSelected] = useState(null);
  const [ticketSelected, setTicketSelected] = useState(null);
  const [view, setView] = useState("month");
  const [tempPartitaIVA, setTempPartitaIVA] = useState(null);
  const [tempFiscalCode, setTempFiscalCode] = useState(null);
  const [availabilities, setAvailabilities] = useState([]);
  const [slotsList, setSlotsList] = useState([]);
  const [structure, setStructure] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [recap, setRecap] = useState(false);
  const colors = useContext(UserContext)?.colors;
  const prevDateSelected = usePrevious({ dateSelected });
  const [isOldCustomer, setIsOldCustomer] = useState(false);
  const [oldCustomerFoundFromPartitaIVA, setOldCustomerFoundFromPartitaIVA] =
    useState(false);
  const [oldCustomerFoundFromFiscalCode, setOldCustomerFoundFromFiscalCode] =
    useState(false);
  const [isFiscalCodeToBeChecked, setIsFiscalCodeToBeChecked] = useState(false);
  const prevIsFiscalCodeToBeChecked = usePrevious(isFiscalCodeToBeChecked);
  const [invoice, setInvoice] = useState(false);

  //Bool used to check whether the invoice is meant for a private customer or for a school/company/group etc.
  const [isPrivate, setIsPrivate] = useState(false);
  const prevIsPrivate = usePrevious(isPrivate);

  const [totalValue, setTotalValue] = useState(0);

  const [settings, setSettings] = useState(null);

  //Flag used to hide the button to go back to calendar when coming from the whole availabilities calendar
  const [hideGoBack, setHideGoBack] = useState(false);

  const assignCustomerValues = (customer) => {
    structure.info_ente[0].value = customer.ragione_sociale;
    structure.info_ente[1].value = customer.via;
    structure.info_ente[2].value = customer.comune;
    structure.info_ente[3].value = customer.provincia;
    structure.info_ente[4].value = customer.cap;
    structure.info_ente[5].value = customer.nazione;
    structure.info_ente[6].value = customer.telefono;
    structure.info_ente[7].value = customer.partita_iva;
    structure.info_ente[8].value = customer.codice_fiscale;
    structure.info_ente[9].value = customer.codice_destinatario;
    structure.info_ente[10].value = customer.cig;
    structure.info_ente[11].value = customer.cup;
    structure.info_ente[12].value = customer.pec;
  };

  const minTime = new Date();
  minTime.setHours(8, 0, 0);
  const maxTime = new Date();
  maxTime.setHours(20, 0, 0);

  const CustomToolbar = (toolbar) => {
    const styles = {
      toolbarContainer: {
        margin: 20,
        marginTop: width > 1000 && 0,
      },
      button: {
        marginLeft: 20,
        marginRight: 20,
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        fontSize: 20,
      },
      row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        margin: 0,
        visibility: view === "month" && "hidden",
      },
      back: {
        fontSize: 10,
        marginLeft: 5,
        cursor: "pointer",
      },
      buttonBack: {
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        fontSize: 20,
        cursor: "pointer",
      },
    };

    const goToBack = () => {
      if (view === "month") {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      } else if (view === "day") {
        toolbar.date.setDate(toolbar.date.getDate() - 1);
      }

      toolbar.onNavigate("prev");
    };

    const goToNext = () => {
      if (view === "month") {
        toolbar.date.setDate(1);
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      } else if (view === "day") {
        toolbar.date.setDate(toolbar.date.getDate() + 1);
      }
      toolbar.onNavigate("next");
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span>
          {view === "day" && <b>{date.format("DD")} </b>}
          <b>{date.format("MMMM")}</b>
          <b> {date.format("YYYY")}</b>
        </span>
      );
    };

    return (
      <>
        {!hideGoBack && (
          <div style={styles.row}>
            <button style={styles.buttonBack} onClick={() => setView("month")}>
              &#8249;
            </button>
            <p style={styles.back} onClick={() => setView("month")}>
              Torna al calendario
            </p>
          </div>
        )}
        <div style={styles.toolbarContainer}>
          <button style={styles.button} onClick={goToBack}>
            &#8249;
          </button>
          <label>{label()}</label>
          <button style={styles.button} onClick={goToNext}>
            &#8250;
          </button>
        </div>
      </>
    );
  };

  const CustomEvent = (event) => {
    return (
      <div
        style={{ display: "block", overflow: "hidden", whiteSpace: "nowrap" }}
      >
        {event.event.relatori.surname}
      </div>
    );
  };

  const getCustomerFromPartitaIVA = (partitaIVA) => {
    customersService.getCustomerFromPartitaIVA(partitaIVA).then((customer) => {
      if (customer.length !== 0) {
        structure.info_ente[8].value = customer.codice_fiscale;
        assignCustomerValues(customer[0]);
        setOldCustomerFoundFromPartitaIVA(true);
      } else {
        setOldCustomerFoundFromPartitaIVA(false);
      }
    });
  };

  const getCustomerFromFiscalCode = (fiscalCode) => {
    customersService.getCustomerFromFiscalCode(fiscalCode).then((customer) => {
      if (customer.length !== 0) {
        structure.info_ente[7].value = customer.partita_iva;
        assignCustomerValues(customer[0]);
        setOldCustomerFoundFromFiscalCode(true);
      } else {
        setOldCustomerFoundFromFiscalCode(false);
      }
    });
  };

  const getAvailabilities = () => {
    activitiesService
      .getActivityAvailabilities(activity.id, info.id_org, dateSelected)
      .then((availabilities) => {
        setAvailabilities(availabilities);
        setLoading(false);
      });
  };

  const isFieldMissing = (field) => {
    return (
      field.mandatory &&
      (field.value === null ||
        field.value === undefined ||
        field.value === "" ||
        field.value === " - ")
    );
  };

  const preventNext = () => {
    let errorInfoEnte = false;
    if (!isOldCustomer) {
      errorInfoEnte = structure.info_ente.some(
        (field) => isFieldMissing(field) && invoice
      );
      if (errorInfoEnte && invoice) {
        setError(
          'Il campo "' +
            structure.info_ente.find((field) => isFieldMissing(field)).name +
            '" è obbligatorio'
        );
      }
    } else {
      var completeError = "";
      if (
        tempPartitaIVA !== null &&
        tempPartitaIVA !== undefined &&
        tempPartitaIVA !== "" &&
        !oldCustomerFoundFromPartitaIVA
      ) {
        completeError =
          "Nessun risultato corrispondente alla Partita IVA inserita";
      }
      if (
        tempFiscalCode !== null &&
        tempFiscalCode !== undefined &&
        tempFiscalCode !== "" &&
        !oldCustomerFoundFromFiscalCode
      ) {
        if (completeError !== "") {
          completeError = completeError + ", né al Codice Fiscale inserito";
        } else {
          completeError =
            "Nessun risultato corrispondente al Codice Fiscale inserito";
        }
      }
      if (completeError !== "") {
        errorInfoEnte = true;
        setError(completeError);
      }
    }
    let errorInfoGruppo = structure.info_gruppo.some((field) =>
      isFieldMissing(field)
    );
    if (errorInfoGruppo) {
      setError(
        'Il campo "' +
          structure.info_gruppo.find((field) => isFieldMissing(field)).name +
          '" è obbligatorio'
      );
    }
    let errorInfoPrenCustom = structure.info_prenotazione_custom.some((field) =>
      isFieldMissing(field)
    );
    if (errorInfoPrenCustom) {
      setError(
        'Il campo "' +
          structure.info_prenotazione_custom.find((field) =>
            isFieldMissing(field)
          ).name +
          '" è obbligatorio'
      );
    }
    let errorInfoPrenDefault = structure.info_prenotazione_default.some(
      (field) => isFieldMissing(field)
    );
    if (errorInfoPrenDefault) {
      setError(
        'Il campo "' +
          structure.info_prenotazione_default.find((field) =>
            isFieldMissing(field)
          ).name +
          '" è obbligatorio'
      );
    }

    let participants = structure.info_prenotazione_default.find(
      (field) => field.name === "Numero partecipanti"
    );
    let errorCapacity = false;
    let errorMessage = "";
    if (participants.value !== "") {
      if (parseInt(participants.value) > activity.capienza) {
        errorCapacity = true;
        errorMessage =
          "La capienza massima è di " + activity.capienza + " partecipanti";
      } else if (parseInt(participants.value) < activity.min_partecipanti) {
        errorCapacity = true;
        errorMessage =
          "Minimo di " +
          activity.min_partecipanti +
          " partecipanti per poter richiedere una prenotazione";
      } else {
        errorCapacity = false;
      }
    }
    if (errorCapacity) {
      setError(errorMessage);
    }

    return (
      errorInfoEnte ||
      errorInfoGruppo ||
      errorInfoPrenCustom ||
      errorInfoPrenDefault ||
      errorCapacity
    );
  };

  //Check whether the activity is free (no invoice needed) or not (invoice needed)
  useEffect(() => {
    if (ticketSelected?.price.toFixed(2) <= 0 || !ticketSelected) {
      setInvoice(false);
    } else {
      setInvoice(true);
    }
  }, [ticketSelected]);

  useEffect(() => {
    if (availabilitySelected) {
      setTimeSelected(null);
      setTicketSelected(null);
      let lastEntrance = moment(availabilitySelected.datetime_fine)
        .subtract(activity.durata, "minutes")
        .toDate();
      setSlotsList(
        getTimeSlots(
          new Date(availabilitySelected.datetime_inizio).getHours() +
            ":" +
            new Date(availabilitySelected.datetime_inizio).getMinutes(),
          new Date(lastEntrance).getHours() +
            ":" +
            new Date(lastEntrance).getMinutes()
        )
      );
      setStep(2);
    } else {
      setStep(1);
    }
  }, [availabilitySelected, activity]);

  useEffect(() => {
    const resetFields = () => {
      if (structure) {
        for (let field of structure.info_ente) {
          if (field.default) {
            field.value = field.default;
          } else {
            field.value = null;
          }
        }
        for (let field of structure.info_gruppo) {
          if (field.default) {
            field.value = field.default;
          } else {
            field.value = null;
          }
        }
        for (let field of structure.info_prenotazione_custom) {
          field.value = null;
        }
        for (let field of structure.info_prenotazione_default) {
          field.value = null;
        }
      }
    };
    if (view === "month") {
      setAvailabilitySelected(null);
      setTimeSelected(null);
      setTicketSelected(null);
      resetFields();
      setRecap(false);
    }
  }, [view, structure]);

  useEffect(() => {
    if (tempFiscalCode !== "") getCustomerFromFiscalCode(tempFiscalCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempFiscalCode]);

  useEffect(() => {
    if (tempPartitaIVA !== "") getCustomerFromPartitaIVA(tempPartitaIVA);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempPartitaIVA]);

  const renderStep = () => {
    switch (step) {
      case 3:
        return (
          <div style={{ height: "100%", overflow: "scroll" }}>
            <div style={styles.row}>
              <button
                style={styles.buttonBack}
                onClick={() => (recap ? setRecap(false) : setStep(step - 1))}
              >
                &#8249;
              </button>
              <p
                style={styles.back}
                onClick={() => (recap ? setRecap(false) : setStep(step - 1))}
              >
                Indietro
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: width > 1000 ? "row" : "column",
                width: "100%",
              }}
            >
              <div style={styles.dataColumn}>
                <p style={styles.title}>Dati Prenotazione</p>
                {!recap && invoice && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      onChange={() => {
                        setIsPrivate(!isPrivate);
                      }}
                      checked={isPrivate}
                    />
                    <p style={{ marginLeft: 10, fontSize: 14 }}>
                      Voglio richiedere la fattura come privato
                    </p>
                  </div>
                )}
                {[
                  ...structure.info_prenotazione_default.sort(
                    (a, b) => a.order - b.order
                  ),
                  ...structure.info_gruppo.sort((a, b) => a.order - b.order),
                ].map((field, key) => {
                  return (
                    <Field
                      key={key}
                      field={field}
                      colors={colors}
                      addField={(newField) => (field.value = newField)}
                      fixed={recap}
                      listLenght={1}
                    />
                  );
                })}
              </div>
              {structure.info_prenotazione_custom.length > 0 && (
                <div style={styles.dataColumn}>
                  <p style={styles.title}>Dati Aggiuntivi</p>
                  {structure.info_prenotazione_custom
                    .sort((a, b) => a.order - b.order)
                    .map((field, key) => {
                      return (
                        <Field
                          key={key}
                          field={field}
                          colors={colors}
                          addField={(newField) => {
                            field.value = newField;
                          }}
                          fixed={recap}
                          listLenght={1}
                        />
                      );
                    })}
                </div>
              )}
              {invoice && (
                <div style={styles.dataColumn}>
                  <p style={styles.title}>Dati Fatturazione</p>

                  {!recap && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="checkbox"
                        onChange={() => {
                          setIsOldCustomer(!isOldCustomer);
                        }}
                        checked={isOldCustomer}
                      />
                      <p
                        style={{
                          marginLeft: 10,
                          fontSize: 14,
                          textAlign: "left",
                        }}
                      >
                        Ho già effettuato una prenotazione per il mio gruppo
                      </p>
                    </div>
                  )}

                  {!recap && isOldCustomer && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 5,
                      }}
                    >
                      <p
                        style={{
                          margin: 10,
                          marginLeft: 0,
                          fontSize: 12,
                          textAlign: "left",
                        }}
                      >
                        Cerca il profilo usando:
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <select
                          name="options"
                          style={{
                            borderRadius: 5,
                            border: "1px solid lightgray",
                            width: "auto",
                            height: 40,
                            textAlign: "left",
                            outline: "none",
                            padding: 10,
                            boxSizing: `border-box`,
                          }}
                          onChange={(event) => {
                            if (event.target.value === "0") {
                              setIsFiscalCodeToBeChecked(false);
                            } else if (event.target.value === "1") {
                              setIsFiscalCodeToBeChecked(true);
                            }
                          }}
                        >
                          <option class={styles.singleOption} value={"0"}>
                            Partita IVA
                          </option>
                          <option class={styles.singleOption} value={"1"}>
                            Codice fiscale
                          </option>
                        </select>
                      </div>
                    </div>
                  )}

                  {!isOldCustomer || recap
                    ? structure.info_ente
                        .sort((a, b) => a.order - b.order)
                        .map((field, key) => {
                          return (
                            <Field
                              key={key}
                              field={field}
                              colors={colors}
                              addField={(newField) => (field.value = newField)}
                              fixed={recap}
                              listLenght={1}
                            />
                          );
                        })
                    : structure.info_ente
                        .sort((a, b) => a.order - b.order)
                        .map((field, key) => {
                          if (
                            !isFiscalCodeToBeChecked &&
                            field.name.includes("IVA")
                          ) {
                            return (
                              <>
                                <Field
                                  key={key}
                                  field={field}
                                  colors={colors}
                                  addField={(newField) => {
                                    field.value = newField;
                                    setTempPartitaIVA(newField);
                                  }}
                                  fixed={recap}
                                  listLenght={1}
                                />
                                <p
                                  style={{
                                    marginLeft: 0,
                                    fontSize: 12,
                                    textAlign: "left",
                                    width: "100%",
                                    color: oldCustomerFoundFromPartitaIVA
                                      ? "#a0c2a0"
                                      : "#c36666",
                                  }}
                                >
                                  {tempPartitaIVA !== ""
                                    ? oldCustomerFoundFromPartitaIVA
                                      ? "Profilo recuperato"
                                      : "Profilo non trovato"
                                    : ""}
                                </p>
                              </>
                            );
                          } else if (
                            isFiscalCodeToBeChecked &&
                            field.name.includes("fiscale")
                          ) {
                            return (
                              <>
                                <Field
                                  key={key}
                                  field={field}
                                  colors={colors}
                                  addField={(newField) => {
                                    field.value = newField;
                                    setTempFiscalCode(newField);
                                  }}
                                  fixed={recap}
                                  listLenght={1}
                                />
                                <p
                                  style={{
                                    marginLeft: 0,
                                    fontSize: 12,
                                    textAlign: "left",
                                    width: "100%",
                                    color: oldCustomerFoundFromFiscalCode
                                      ? "#a0c2a0"
                                      : "#c36666",
                                  }}
                                >
                                  {tempFiscalCode !== ""
                                    ? oldCustomerFoundFromFiscalCode
                                      ? "Profilo recuperato"
                                      : "Profilo non trovato"
                                    : ""}
                                </p>
                              </>
                            );
                          } else {
                            return null;
                          }
                        })}
                </div>
              )}
            </div>
          </div>
        );
      case 4:
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                ...styles.row,
                width: "100%",
                alignItems: "flex-start",
                marginBottom: 40,
              }}
            >
              <div style={styles.row}>
                <button
                  style={styles.buttonBack}
                  onClick={() => {
                    getAvailabilities();
                    setStep(1);
                    setView("month");
                  }}
                >
                  &#8249;
                </button>
                <p
                  style={styles.back}
                  onClick={() => {
                    getAvailabilities();
                    setStep(1);
                    setView("month");
                  }}
                >
                  Torna alle attività
                </p>
              </div>
            </div>
            <div
              style={{
                width: 80,
                padding: 20,
                borderRadius: 40,
                paddingBottom: 25,
                paddingTop: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                objectFit: "cover",
                backgroundColor: "green",
                boxSizing: `border-box`,
                marginRight: 30,
              }}
            >
              <img
                alt={"Checked icon"}
                src={checked}
                style={{ height: 30 }}
              ></img>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "70%" }}
            >
              <p style={styles.feedback}>Richiesta inoltrata correttamente</p>
              <p style={{ textAlign: "justify" }}>
                La richiesta di prenotazione è stata inoltrata correttamente. A
                breve riceverai un'email con il recap della tua richiesta di
                prenotazione. <br />
                <br />
                <b>Attenzione.</b> Questa non è una conferma prenotazione. La
                richiesta è da considerarsi confermata solo in seguito alla
                ricezione dell'e-mail di <b>conferma prenotazione</b>.
              </p>
            </div>
          </div>
        );
      case 5:
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                ...styles.row,
                width: "100%",
                alignItems: "flex-start",
                marginBottom: 40,
              }}
            >
              <div style={styles.row}>
                <button
                  style={styles.buttonBack}
                  onClick={() => {
                    setStep(1);
                    setView("month");
                  }}
                >
                  &#8249;
                </button>
                <p
                  style={styles.back}
                  onClick={() => {
                    setStep(1);
                    setView("month");
                  }}
                >
                  Torna alle attività
                </p>
              </div>
            </div>
            <div
              style={{
                width: 80,
                padding: 20,
                borderRadius: 40,
                paddingBottom: 25,
                paddingTop: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                objectFit: "cover",
                backgroundColor: "red",
                boxSizing: `border-box`,
                marginRight: 30,
              }}
            >
              <img
                alt={"Error icon"}
                src={errorIcon}
                style={{ height: 30 }}
              ></img>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "70%" }}
            >
              <p style={styles.feedback}>
                Errore durante l'invio della richiesta
              </p>
              <p style={{ textAlign: "center" }}>
                Siamo spiacenti, la richiesta di prenotazione non è andata a
                buon fine.
                <br />
                <br />
                Contatta l'assistenza all'indirizzo emma@rnb4culture.com.
              </p>
            </div>
          </div>
        );
      default:
        return (
          <Calendar
            date={moment(dateSelected).toDate()}
            localizer={localizer}
            events={availabilities}
            startAccessor={(event) => {
              return new Date(event.datetime_inizio);
            }}
            endAccessor={(event) => {
              return new Date(event.datetime_fine);
            }}
            min={minTime}
            max={maxTime}
            style={{ height: height }}
            view={view}
            defaultView={view}
            components={{
              toolbar: CustomToolbar,
              event: CustomEvent,
            }}
            onNavigate={(date) => setDateSelected(new Date(date))}
            popup={true}
            onSelectEvent={(event) => {
              if (moment(event.datetime_inizio) > moment()) {
                if (view === "month") {
                  setView("day");
                }
                setDateSelected(event.datetime_inizio);
                setAvailabilitySelected(event);
              }
            }}
            dayPropGetter={(date) => {
              let dayStyle = {
                backgroundColor:
                  moment(date).add(1, "days") < moment()
                    ? colors?.verylightgray
                    : "transparent",
              };
              return {
                className: "",
                style: dayStyle,
              };
            }}
            eventPropGetter={(event, start, end, isSelected) => {
              let newStyle = {
                backgroundColor: event.relatori.color,
                color: checkColor(event.relatori.color),
                borderRadius: 5,
                outline: "none",
                borderTop: "8px solid",
                borderBottom: "8px solid",
                borderRightWidth: width > 800 ? "8px solid" : "0",
                borderLeftWidth: width > 800 ? "8px solid" : "0",
                borderColor:
                  isSelected &&
                  view === "day" &&
                  event.relatori.color !== undefined
                    ? shadeColor(event.relatori.color, -15)
                    : event.relatori.color !== undefined
                    ? event.relatori.color
                    : "white",
                fontSize: 13,
              };
              return {
                className: "",
                style: newStyle,
              };
            }}
          />
        );
    }
  };

  const renderRightColumn = () => {
    switch (step) {
      case 1:
        return (
          <div style={styles.column}>
            <p style={styles.title}>Relator{speakers.length > 1 ? "i" : "e"}</p>
            <div style={styles.column}>
              {speakers.length > 0 ? (
                speakers.map((speaker, key) => {
                  return (
                    <div
                      key={key}
                      style={{
                        ...styles.row,
                        margin: 10,
                        cursor: "pointer",
                        marginBottom: 20,
                      }}
                    >
                      <img
                        alt={"Foto relatore"}
                        src={speaker.photo?.url || user}
                        style={{
                          width: 50,
                          height: 50,
                          borderRadius: 50,
                          objectFit: "cover",
                        }}
                      ></img>
                      <p style={{ marginLeft: 10, fontWeight: 500 }}>
                        {speaker.name + " " + speaker.surname}
                      </p>
                    </div>
                  );
                })
              ) : (
                <p style={{ fontSize: 14, textAlign: "left" }}>
                  Nessun relatore disponibile
                </p>
              )}
            </div>
          </div>
        );
      case 2:
        return (
          <div style={styles.column}>
            <p style={styles.title}>Relatore selezionato</p>
            <div style={styles.column}>
              <div
                style={{
                  ...styles.row,
                  margin: 10,
                  cursor: "pointer",
                  marginBottom: 20,
                }}
              >
                <img
                  alt={"Foto relatore"}
                  src={availabilitySelected?.relatori.photo?.url || user}
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 50,
                    objectFit: "cover",
                  }}
                ></img>
                <p style={{ marginLeft: 10, fontWeight: 500 }}>
                  {availabilitySelected?.relatori.name +
                    " " +
                    availabilitySelected?.relatori.surname}
                </p>
              </div>
            </div>
            <p style={styles.title}>Seleziona orario</p>
            <div style={styles.column}>
              {slotsList.map((slot, key) => {
                return (
                  <div
                    key={key}
                    style={{
                      ...styles.slot,
                      backgroundColor:
                        timeSelected === slot ? "#303030" : "#f0f0f0",
                      color: timeSelected === slot ? "white" : "black",
                    }}
                    onClick={() => setTimeSelected(slot)}
                  >
                    {slot}
                  </div>
                );
              })}
            </div>
            {timeSelected && (
              <>
                <br />
                <p style={{ ...styles.title, marginBottom: 0 }}>
                  Seleziona tariffa
                </p>
                <p style={styles.subtitle}>
                  {" "}
                  {activity.pagamento_individuale
                    ? "Il valore selezionato indica il costo per singolo partecipante, esclusi gli accompagnatori"
                    : "Il valore selezionato indica il costo totale per l'intero gruppo e non é da intendersi come costo per singolo partecipante"}
                </p>
                <div style={styles.column}>
                  {activity.biglietti.map((ticket, key) => {
                    return (
                      <div
                        key={key}
                        style={{
                          ...styles.ticket,
                          backgroundColor:
                            ticketSelected?.id === ticket.id
                              ? "#303030"
                              : "#f0f0f0",
                          color:
                            ticketSelected?.id === ticket.id
                              ? "white"
                              : "black",
                        }}
                        onClick={() => setTicketSelected(ticket)}
                      >
                        <p style={{ margin: 5, marginTop: 10 }}>
                          {ticket.name}
                        </p>
                        <b style={{ margin: 5, marginBottom: 10 }}>
                          {"€" + ticket.price.toFixed(2)}
                        </b>
                      </div>
                    );
                  })}
                </div>
                {activity.pagamento_individuale && recap && (
                  <>
                    <div
                      style={{ ...styles.row, justifyContent: "space-between" }}
                    >
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: 700,
                          textAlign: "left",
                        }}
                      >
                        Costo totale
                      </p>
                    </div>
                    <div
                      style={{
                        ...styles.ticket,
                        backgroundColor: "white",
                        border: "1px solid black",
                        color: "black",
                      }}
                    >
                      <b style={{ margin: 5, marginBottom: 10 }}>
                        {"€" + totalValue}
                      </b>
                    </div>
                  </>
                )}
                {settings.impostazioni_attivita.info_pagamento !== null &&
                  settings.impostazioni_attivita.info_pagamento !== undefined &&
                  settings.impostazioni_attivita.info_pagamento !== "" && (
                    <>
                      <div
                        style={{
                          ...styles.row,
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 18,
                            fontWeight: 700,
                            textAlign: "left",
                          }}
                        >
                          Informazioni per il pagamento
                        </p>
                      </div>
                      <p style={styles.subtitle}>
                        {settings.impostazioni_attivita.info_pagamento}
                      </p>
                    </>
                  )}
              </>
            )}
            {timeSelected && ticketSelected && (
              <button
                style={styles.reserveButton}
                onClick={() => {
                  setIsOldCustomer(false);
                  setStep(3);
                  if (width < 1000) {
                    window.scrollTo({
                      top: 800,
                      left: 0,
                      behavior: "smooth",
                    });
                  }
                }}
              >
                AVANTI
              </button>
            )}
          </div>
        );
      default:
        return (
          <div style={styles.column}>
            <p style={{ fontSize: 18, fontWeight: 700, textAlign: "left" }}>
              Relatore selezionato
            </p>
            <div style={styles.column}>
              <div
                style={{
                  ...styles.row,
                  margin: 10,
                  cursor: "pointer",
                  marginBottom: 20,
                }}
              >
                <img
                  alt={"Foto relatore"}
                  src={availabilitySelected?.relatori.photo?.url || user}
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 50,
                    objectFit: "cover",
                  }}
                ></img>
                <p style={{ marginLeft: 10, fontWeight: 500 }}>
                  {availabilitySelected?.relatori.name +
                    " " +
                    availabilitySelected?.relatori.surname}
                </p>
              </div>
            </div>
            <div style={{ ...styles.row, justifyContent: "space-between" }}>
              <p style={{ fontSize: 18, fontWeight: 700, textAlign: "left" }}>
                Orario selezionato
              </p>
              {!recap && (
                <img
                  alt={"Remove button"}
                  src={remove}
                  style={{
                    height: 13,
                    width: 13,
                    objectFit: "contain",
                    padding: 5,
                    backgroundColor: "black",
                    borderRadius: 50,
                  }}
                  onClick={() => {
                    setTimeSelected(null);
                    setTicketSelected(null);
                    setStep(2);
                  }}
                ></img>
              )}
            </div>
            <div style={styles.column}>
              <div
                style={{
                  ...styles.slot,
                  backgroundColor: "white",
                  border: "1px solid black",
                  color: "black",
                }}
              >
                {timeSelected}
              </div>
            </div>

            <br />
            <div style={{ ...styles.row, justifyContent: "space-between" }}>
              <p style={{ fontSize: 18, fontWeight: 700, textAlign: "left" }}>
                Biglietto selezionato
              </p>
              {!recap && (
                <img
                  alt={"Remove button"}
                  src={remove}
                  style={{
                    height: 13,
                    width: 13,
                    objectFit: "contain",
                    padding: 5,
                    backgroundColor: "black",
                    borderRadius: 50,
                  }}
                  onClick={() => {
                    setTicketSelected(null);
                    setStep(2);
                  }}
                ></img>
              )}
            </div>
            <div style={styles.column}>
              <div
                style={{
                  ...styles.ticket,
                  backgroundColor: "white",
                  border: "1px solid black",
                  color: "black",
                }}
              >
                <p style={{ margin: 5, marginTop: 10 }}>
                  {ticketSelected.name}
                </p>
                <b style={{ margin: 5, marginBottom: 10 }}>
                  {"€" + ticketSelected.price.toFixed(2)}
                </b>
              </div>
            </div>
            {settings.impostazioni_attivita.info_pagamento !== null &&
              settings.impostazioni_attivita.info_pagamento !== "" && (
                <>
                  <div
                    style={{ ...styles.row, justifyContent: "space-between" }}
                  >
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: 700,
                        textAlign: "left",
                      }}
                    >
                      Informazioni per il pagamento
                    </p>
                  </div>
                  <p style={styles.subtitle}>
                    {settings.impostazioni_attivita.info_pagamento}
                  </p>
                </>
              )}

            {step < 4 &&
              (buttonLoading === true ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 40,
                    flexDirection: "column",
                  }}
                >
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={"black"}
                    height={50}
                    width={50}
                  />
                  <p style={{ fontSize: 11, marginTop: 30 }}>
                    Stiamo elaborando la tua richiesta, l'operazione potrebbe
                    durare qualche secondo
                  </p>
                </div>
              ) : (
                <button
                  style={styles.reserveButton}
                  onClick={() => {
                    if (preventNext()) {
                      setShowError(true);
                    } else {
                      setShowError(false);
                      setError(null);
                      if (recap) {
                        let hour = timeSelected.split(":")[0];
                        let minutes = timeSelected.split(":")[1];
                        const date = new Date(dateSelected).setHours(
                          hour,
                          minutes,
                          0
                        );
                        setButtonLoading(true);
                        activitiesService
                          .createOnline(
                            generateBody(
                              info.id_org,
                              activity.id,
                              availabilitySelected.id,
                              new Date(date),
                              [
                                ...structure.info_prenotazione_default,
                                ...structure.info_gruppo,
                              ],
                              structure.info_prenotazione_custom,
                              structure.info_ente,
                              availabilitySelected.relatori.id,
                              ticketSelected,
                              null,
                              null,
                              settings.impostazioni_attivita.info_pagamento !==
                                null
                                ? settings.impostazioni_attivita.info_pagamento
                                : "",
                              activity.pagamento_individuale,
                              totalValue
                            )
                          )
                          .then((response) => {
                            if (width < 1000) {
                              window.scrollTo({
                                top: 800,
                                left: 0,
                                behavior: "smooth",
                              });
                            }
                            setButtonLoading(false);
                            if (response.status) {
                              setStep(4);
                            } else {
                              setStep(5);
                            }
                          });
                      } else {
                        setRecap(true);
                        if (activity.pagamento_individuale) {
                          setTotalValue(
                            ticketSelected.price.toFixed(2) *
                              structure.info_prenotazione_default.find(
                                (field) => field.name === "Numero partecipanti"
                              ).value
                          );
                        }
                      }
                    }
                  }}
                >
                  {recap ? "RICHIEDI PRENOTAZIONE" : "AVANTI"}
                </button>
              ))}
            {showError && (
              <p
                style={{
                  color: "darkred",
                  marginTop: 10,
                  fontSize: 10,
                  width: "100%",
                }}
              >
                {error}
              </p>
            )}
          </div>
        );
    }
  };

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
      const newHeight = window.innerHeight;
      setHeight(newHeight);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, [props]);

  useEffect(() => {
    const getActivity = (props) => {
      eventsService.getActivity(props.match.params.id).then((activity) => {
        setActivity(activity);
      });
    };
    getActivity(props);
  }, [props]);

  useEffect(() => {
    const getInfo = () => {
      settingsService.getAllMuseums().then((list) => {
        let museum = list.find(
          (mus) => mus.identificativo_museo === props.match.params.museum
        );
        setInfo(museum);
        getAvailabilities(props, museum.id_org, new Date());

        settingsService.getOrganisationFromId(museum.id_org).then((org) => {
          settingsService
            .getSettingsFromId(org.settings.id)
            .then((settings) => {
              setSettings(settings);
            });
        });
      });
    };

    const getAvailabilities = (props, orgId) => {
      activitiesService
        .getActivityAvailabilities(props.match.params.id, orgId, new Date())
        .then((availabilities) => {
          setAvailabilities(availabilities);
          setLoading(false);
        });
    };

    getInfo();
  }, [props]);

  useEffect(() => {
    const getStructure = () => {
      if (prevIsPrivate !== isPrivate) setLoading(true);
      eventsService
        .getActivityStructure(
          props.match.params.id,
          isPrivate,
          invoice,
          !isFiscalCodeToBeChecked && isOldCustomer
        )
        .then((struct) => {
          for (const elem of struct.info_prenotazione_default) {
            if (elem.type === 6 && elem.default) {
              elem.value = elem.default;
            }
          }
          for (const elem of struct.info_gruppo) {
            if (elem.type === 6 && elem.default) {
              elem.value = elem.default;
            }
          }
          for (const elem of struct.info_prenotazione_custom) {
            if (elem.type === 6 && elem.default) {
              elem.value = elem.default;
            }
          }
          for (const elem of struct.info_ente) {
            if (elem.type === 6 && elem.default) {
              elem.value = elem.default;
            }
          }

          setStructure(struct);
          setLoading(false);
        });
    };

    getStructure();
  }, [
    invoice,
    isPrivate,
    prevIsPrivate,
    isFiscalCodeToBeChecked,
    isOldCustomer,
    prevIsFiscalCodeToBeChecked,
    props,
  ]);

  useEffect(() => {
    const getAvailabilities = (props, orgId) => {
      activitiesService
        .getActivityAvailabilities(props.match.params.id, orgId, dateSelected)
        .then((availabilities) => {
          setAvailabilities(availabilities);
          setLoading(false);
        });
    };
    let prevMonth = new Date(prevDateSelected?.dateSelected).getMonth();
    let currentMonth = new Date(dateSelected).getMonth();
    if (prevMonth !== currentMonth && info) {
      getAvailabilities(props, info.id_org, dateSelected);
    }
  }, [dateSelected, prevDateSelected, info, props]);

  useEffect(() => {
    setSpeakers(getSpeakersForActivity(availabilities));
  }, [availabilities]);

  useEffect(() => {
    //Check if we come from the full calendar view, in order to already open the selected event and time slot
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    const mainId = params.get("mainId");
    let date = params.get("date");

    if (id !== null && date !== null) {
      date = new Date(date);
      setDateSelected(date);
      setHideGoBack(true);

      settingsService.getAllMuseums().then((list) => {
        let museum = list.find(
          (mus) => mus.identificativo_museo === props.match.params.museum
        );
        setInfo(museum);
        if (availabilities.length <= 0) {
          getAvailabilities(museum.id_org);
        }

        settingsService.getOrganisationFromId(museum.id_org).then((org) => {
          settingsService
            .getSettingsFromId(org.settings.id)
            .then((settings) => {
              setSettings(settings);
            });
        });
      });

      const getAvailabilities = (orgId) => {
        activitiesService
          .getActivityAvailabilities(id, orgId, date)
          .then((availabilities) => {
            setAvailabilities(availabilities);
            setLoading(false);
            let event = availabilities.find((av) => av.id === parseInt(mainId));
            if (event) {
              setView("day");
              setDateSelected(event.datetime_inizio);
              setAvailabilitySelected(event);
            }
          });
      };
    }
  }, [availabilities.length, props.match.params.museum]);

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return text?.length > 250 ? (
      <p style={styles.description}>
        {isReadMore ? text.slice(0, 250) : text}
        <span
          onClick={toggleReadMore}
          style={{ ...styles.description, fontWeight: 700, cursor: "pointer" }}
        >
          {isReadMore ? " ...mostra altro" : " ...mostra meno"}
        </span>
      </p>
    ) : (
      <p style={styles.description}>{text}</p>
    );
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      alignItems: "center",
    },
    loaderBox: {
      flex: 1,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    topBar: {
      width: "100%",
      height: 60,
      backgroundColor: "black",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    header: { height: height * 0.2, width: width, objectFit: "cover" },

    button: {
      outline: "none",
      backgroundColor: "white",
      border: "none",
      borderRadius: 50,
      width: 200,
      padding: 10,
      fontWeight: "800",
      cursor: "pointer",
    },
    arrowBack: {
      height: 30,
      objectFit: "contain",
      outline: "none",
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: 10,
    },

    responsiveRow: {
      display: "flex",
      flexDirection: width > 1000 ? "row" : "column",
      height: width > 1000 && "100%",
      width: "100%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    column: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    leftColumn: {
      height: width > 1000 && "100%",
      width: width > 1000 && "20%",
      backgroundColor: "black",
      display: "flex",
      flexDirection: "column",
      padding: 20,
      overflow: width > 1000 && "scroll",
      paddingBottom: 40,
    },
    body: {
      flex: 1,
      height: "100%",
      padding: 20,
      paddingBottom: 40,
    },
    rightColumn: {
      height: width > 1000 && "100%",
      width: width > 1000 && "15%",
      display: "flex",
      flexDirection: "column",
      padding: 20,
      overflow: width > 1000 && "scroll",
      paddingBottom: 40,
    },
    description: {
      fontSize: 14,
      textAlign: "left",
      margin: 0,
      color: "white",
      lineHeight: 1.4,
    },
    label: {
      fontSize: 12,
      textAlign: "left",
      color: "white",
      fontWeight: 800,
    },
    slot: {
      borderRadius: 5,
      backgroundColor: "#f0f0f0",
      margin: 5,
      height: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
    ticket: {
      borderRadius: 5,
      backgroundColor: "#f0f0f0",
      margin: 5,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      cursor: "pointer",
    },
    reserveButton: {
      outline: "none",
      backgroundColor: "#8b0000",
      color: "white",
      border: "none",
      borderRadius: 5,
      padding: 20,
      marginTop: 40,
      fontWeight: "800",
      cursor: "pointer",
    },
    dataColumn: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      marginLeft: 20,
      marginRight: 20,
    },
    title: { fontSize: 18, fontWeight: 700, textAlign: "left" },
    subtitle: {
      fontSize: 14,
      fontWeight: 300,
      textAlign: "left",
      marginTop: "5px",
    },
    back: {
      fontSize: 10,
      marginLeft: 5,
      cursor: "pointer",
    },
    buttonBack: {
      outline: "none",
      backgroundColor: "transparent",
      border: "none",
      fontSize: 20,
      cursor: "pointer",
    },
    feedback: {
      fontSize: 25,
      textAlign: "center",
      fontWeight: "600",
    },
  };

  return (
    <div style={styles.container}>
      {!loading && (
        <div style={styles.topBar}>
          <img
            alt={"Logo museo"}
            src={info?.logo.url}
            style={{ height: "80%", objectFit: "contain", marginLeft: 50 }}
          ></img>
          <img
            alt={"Logo EMMA"}
            src={logo}
            style={{ height: "50%", objectFit: "contain", marginRight: 50 }}
          ></img>
        </div>
      )}
      {!loading && (
        <img
          alt={"Header"}
          src={activity?.immagine?.url}
          style={styles.header}
        ></img>
      )}
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#303030"}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <div style={styles.responsiveRow}>
          <div style={styles.leftColumn}>
            <button
              style={styles.arrowBack}
              onClick={() => {
                window.location =
                  `${process.env.REACT_APP_WEB_URL}/` +
                  props.match.params.museum +
                  "/activities";
              }}
            >
              <img
                alt={"Go back"}
                src={back}
                style={{
                  height: "40%",
                  objectFit: "contain",
                  marginRight: 10,
                }}
              ></img>
              <p
                style={{
                  color: "white",
                  fontSize: 11,
                  fontWeight: 500,
                }}
              >
                Indietro
              </p>
            </button>
            <div style={styles.column}>
              <p
                style={{
                  fontSize: 18,
                  color: "white",
                  fontWeight: 700,
                  marginTop: 20,
                }}
              >
                {activity.nome}
              </p>
            </div>
            <div style={styles.column}>
              <p style={styles.label}>Abstract</p>
              <ReadMore>{activity.abstract}</ReadMore>
            </div>
            <div style={styles.row}>
              <p style={styles.label}>Durata</p>
              <p style={{ ...styles.description, marginLeft: 35 }}>
                {activity.durata} min
              </p>
            </div>
            <div style={styles.row}>
              <p style={styles.label}>Capienza</p>
              <p style={{ ...styles.description, marginLeft: 20 }}>
                {activity.capienza} persone
              </p>
            </div>
            <div style={styles.column}>
              <p style={styles.label}>Informazioni</p>
              <ReadMore>{activity.descrizione}</ReadMore>
            </div>
          </div>

          <div style={styles.body}>{renderStep(step)}</div>
          <div style={styles.rightColumn}>{renderRightColumn(step)}</div>
        </div>
      )}
    </div>
  );
};

export default ActivityView;
