import React, { useState } from "react";
import { eventsService, reservationsService } from "../../../../_services";
import Field from "../../../../components/field";
import close from "./../../../../img/closeButton.png";
import remove from "./../../../../img/minus_white.png";
import moment from "moment";
import trashsec from "./../../../../img/delete.png";
// import pen from "./../../../../img/modify.png";
import ReactLoading from "react-loading";

function onClick(
  step,
  id,
  setStructure,
  setStep,
  defaultProps,
  customProps,
  billingProps,
  operators,
  location,
  setIsError,
  setMessage,
  refresh,
  closeModal,
  resId,
  onModify,
  setLoader,
  refreshActivitySelected
) {
  switch (step) {
    case 1:
      eventsService.getActivityStructure(id).then((structure) => {
        setStructure(structure);
        setStep(step + 1);
      });
      break;
    case 2:
      setStep(step + 1);
      break;
    case 3:
      setStep(step + 1);
      break;
    case 4:
      setLoader(true);
      if (onModify) {
        reservationsService
          .modifyActivityReservation(
            resId,
            generateBody(
              id,
              defaultProps,
              customProps,
              billingProps,
              operators,
              location
            )
          )
          .then((response) => {
            setIsError(!response.status);
            if (!response.status) {
              setMessage(response.message);
              setLoader(false);
            } else {
              refresh(response.message);
              setLoader(false);
            }
            refreshActivitySelected(resId);
            setStep(step + 1);
          });
      } else {
        reservationsService
          .createActivityReservation(
            generateBody(
              id,
              defaultProps,
              customProps,
              billingProps,
              operators,
              location
            )
          )
          .then((response) => {
            setIsError(!response.status);
            if (!response.status) {
              setMessage(response.message);
              setLoader(false);
            } else {
              refresh(response.message);
              setLoader(false);
            }
            setStep(step + 1);
          });
      }
      break;
    case 5:
      closeModal();
      break;

    default:
      break;
  }
}

function addDefaultField(newField, defaultProps, setDefaultProps) {
  let fieldIndex = defaultProps.findIndex(
    (prop) => prop.name === newField.name
  );
  if (fieldIndex === -1) {
    defaultProps.push(newField);
  } else {
    let temp = defaultProps;
    temp[fieldIndex].value = newField.value;
    setDefaultProps(temp);
  }
}

function addCustomField(newField, customProps, setCustomProps) {
  let fieldIndex = customProps.findIndex((prop) => prop.name === newField.name);
  if (fieldIndex === -1) {
    customProps.push(newField);
  } else {
    let temp = customProps;
    temp[fieldIndex].value = newField.value;
    setCustomProps(temp);
  }
}

function addBillingField(newField, billingProps, setBillingProps) {
  let fieldIndex = billingProps.findIndex(
    (prop) => prop.name === newField.name
  );
  if (fieldIndex === -1) {
    billingProps.push(newField);
  } else {
    let temp = billingProps;
    temp[fieldIndex].value = newField.value;
    setBillingProps(temp);
  }
}

function generateBody(
  id,
  defaultProps,
  customProps,
  billingProps,
  operators,
  location
) {
  var obj = "{";
  obj = obj + '"Attività*":' + id + ",";
  for (let i = 0; i < defaultProps.length; i++) {
    obj =
      obj + '"' + defaultProps[i].name + '":"' + defaultProps[i].value + '",';
  }
  obj = obj + '"Operatore":[';
  let opList = [];
  for (let i = 0; i < operators.length; i++) {
    opList.push(operators[i].id);
  }
  obj = obj + opList + "],";
  obj = obj + '"Spazio":"' + location + '",';
  obj = obj + '"ente": {';
  for (let i = 0; i < billingProps.length; i++) {
    obj =
      obj + '"' + billingProps[i].name + '":"' + billingProps[i].value + '"';
    if (i < billingProps.length - 1) {
      obj = obj + ",";
    }
  }
  obj = obj + "},";
  obj = obj + '"valori": ' + JSON.stringify(customProps);
  obj = obj + "}";

  return JSON.parse(obj);
}

const CreateActivityReservation = ({
  list,
  closeModal,
  colors,
  operatorsList,
  refresh,
  activitySelected,
  refreshActivitySelected,
  showReservation,
  onDeleteReservation,
  canDelete,
}) => {
  const [step, setStep] = useState(showReservation === true ? 6 : 1);
  const [category, setCategory] = useState(
    showReservation ? activitySelected.attivita.categoria : list[0]
  );
  const [activity, setActivity] = useState(
    showReservation ? activitySelected : category?.lista_attivita[0]
  );
  const [structure, setStructure] = useState(null);
  const [defaultProps, setDefaultProps] = useState(
    showReservation
      ? [
          ...activitySelected.info_prenotazione_default,
          ...activitySelected.info_gruppo,
        ]
      : []
  );
  const [billingProps, setBillingProps] = useState(
    showReservation ? activitySelected.info_ente : []
  );
  const [customProps, setCustomProps] = useState(
    showReservation ? activitySelected.info_prenotazione_custom : []
  );
  const [operators, setOperators] = useState(
    showReservation ? activitySelected.operatori : []
  );
  const [location, setLocation] = useState(
    showReservation ? activitySelected.spazio?.nome : null
  );
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(false);

  // const [onModify, setOnModify] = useState(false);
  const onModify = false;

  const [loader, setLoader] = useState(false);

  React.useEffect(() => {
    if (category?.lista_attivita?.length > 0) {
      setActivity(category?.lista_attivita[0]);
    }
  }, [step, category]);

  React.useEffect(() => {
    if (onModify === true) {
      setStructure(activitySelected);
      setOperators(activitySelected.operatori);
      setStep(2);
    } else {
      setStructure(null);
    }
  }, [onModify, activitySelected]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      width: step !== 2 && step !== 4 && step !== 6 ? "40%" : "80%",
      height: step !== 2 && step !== 4 && step !== 6 ? "40%" : "80%",
      zIndex: 10,
    },
    header: {
      display: "flex",
      width: "100%",
      height: 80,
      backgroundColor: colors.primary,
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    content: {
      width: "100%",
      flex: 6,
      backgroundColor: colors.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderBottomLeftRadius: step === 6 && 10,
      borderBottomRightRadius: step === 6 && 10,
    },
    field: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginLeft: 50,
      marginRight: 50,
    },
    footer: {
      display: "flex",
      width: "100%",
      height: 120,
      backgroundColor: colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    menu: {
      border: "1px solid lightgray",
      width: "80%",
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
      color: colors.darkgray,
      borderRadius: 5,
      backgroundColor: colors.white,
      outline: "none",
    },
    dataColumn: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      width: "100%",
      padding: 20,
      overflow: "scroll",
    },
    title: {
      marginBottom: 40,
      fontSize: 15,
      fontWeight: "700",
      color: colors.darkgray,
    },
    propButton: {
      border: 0,
      outline: "none",
      borderRadius: 20,
      backgroundColor: colors.primary,
      height: 20,
      width: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: 20,
    },
    loaderBox: {
      display: "flex",
      height: 400,
      zIndex: 300,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: colors.gray,
          opacity: 0.3,
        }}
      />
      <div style={styles.container}>
        <div style={styles.header}>
          <p style={{ color: colors.white, fontSize: 20 }}>
            {step !== 4
              ? step === 5 && isError
                ? "ERRORE"
                : "PRENOTAZIONE ATTIVITÀ"
              : "RECAP"}
          </p>
          {activitySelected && !onModify && canDelete && (
            <>
              <button
                style={{
                  position: "absolute",
                  right: 60,
                  marginTop: "auto",
                  marginBottom: "auto",
                  border: "none",
                  backgroundColor: "transparent",
                  outline: "none",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteReservation(activitySelected.id);
                }}
              >
                <img
                  alt="Delete Event Button"
                  src={trashsec}
                  style={{ height: 15 }}
                />
              </button>
              {/* <button
                style={{
                  position: "absolute",
                  right: 90,
                  marginTop: "auto",
                  marginBottom: "auto",
                  border: "none",
                  backgroundColor: "transparent",
                  outline: "none",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOnModify(!onModify);
                }}
              >
                <img
                  alt="Modify Event Button"
                  src={pen}
                  style={{ height: 15 }}
                />
              </button> */}
            </>
          )}
          <button
            onClick={closeModal}
            style={{
              position: "absolute",
              right: 30,
              marginTop: "auto",
              marginBottom: "auto",
              border: "none",
              backgroundColor: "transparent",
              outline: "none",
              cursor: "pointer",
            }}
          >
            <img
              alt="App logo"
              src={close}
              style={{ width: 15, objectFit: "contain" }}
            />
          </button>
        </div>
        {loader ? (
          <div style={styles.loaderBox}>
            <ReactLoading
              type={"spinningBubbles"}
              color={colors.primary}
              height={50}
              width={50}
            />
          </div>
        ) : (
          <div style={styles.content}>
            {step === 1 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ color: colors.gray }}>Scegli la categoria</p>
                <select
                  name="attività"
                  style={styles.menu}
                  value={category?.id}
                  onChange={(event) => {
                    setCategory(
                      list.find(
                        (cat) => cat.id === parseInt(event.target.value)
                      )
                    );
                  }}
                >
                  {list.map((category, key) => {
                    return (
                      <option key={key} value={category?.id}>
                        {category?.nome}
                      </option>
                    );
                  })}
                </select>
                {category?.lista_attivita?.length > 0 ? (
                  <>
                    <p style={{ color: colors.gray }}>Scegli l'attività</p>
                    <select
                      name="attività"
                      style={styles.menu}
                      value={activity.id}
                      onChange={(event) => {
                        setActivity(
                          category.lista_attivita.find(
                            (act) => act.id === parseInt(event.target.value)
                          )
                        );
                      }}
                    >
                      {category?.lista_attivita.map((activity, key) => {
                        return (
                          <option key={key} value={activity?.id}>
                            {activity.nome}
                          </option>
                        );
                      })}
                    </select>
                  </>
                ) : (
                  <p style={{ color: colors.gray }}>
                    Nessuna attività disponibile per la categoria selezionata.
                  </p>
                )}
              </div>
            )}
            {step === 2 && (
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <div style={styles.dataColumn}>
                  <p style={styles.title}>Dati Prenotazione</p>
                  {[
                    ...structure.info_prenotazione_default.sort(
                      (a, b) => a.order - b.order
                    ),
                    ...structure.info_gruppo.sort((a, b) => a.order - b.order),
                  ].map((field, key) => {
                    return (
                      <Field
                        key={key}
                        field={field}
                        colors={colors}
                        addField={(newField) =>
                          addDefaultField(
                            newField,
                            defaultProps,
                            setDefaultProps
                          )
                        }
                        listLenght={1}
                      />
                    );
                  })}
                </div>
                <div style={styles.dataColumn}>
                  <p style={styles.title}>Dati Aggiuntivi</p>
                  {structure.info_prenotazione_custom
                    .sort((a, b) => a.order - b.order)
                    .map((field, key) => {
                      return (
                        <Field
                          key={key}
                          field={field}
                          colors={colors}
                          addField={(newField) =>
                            addCustomField(
                              newField,
                              customProps,
                              setCustomProps
                            )
                          }
                          listLenght={1}
                        />
                      );
                    })}
                </div>
                <div style={styles.dataColumn}>
                  <p style={styles.title}>Dati Fatturazione</p>
                  {structure.info_ente
                    .sort((a, b) => a.order - b.order)
                    .map((field, key) => {
                      return (
                        <Field
                          key={key}
                          field={field}
                          colors={colors}
                          addField={(newField) =>
                            addBillingField(
                              newField,
                              billingProps,
                              setBillingProps
                            )
                          }
                          listLenght={1}
                        />
                      );
                    })}
                </div>
              </div>
            )}
            {step === 3 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ color: colors.gray }}>Operatori</p>
                {operators.map((op, key) => {
                  return (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          margin: 5,
                          marginRight: 10,
                          fontSize: 12,
                          flex: 1,
                          textAlign: "left",
                        }}
                      >
                        {op.username}
                      </p>
                      <button
                        style={{
                          ...styles.propButton,
                          backgroundColor: colors.secondary,
                        }}
                        onClick={() =>
                          setOperators(operators.filter((o) => o.id !== op.id))
                        }
                      >
                        <img
                          alt={"Remove prop"}
                          src={remove}
                          style={{
                            width: 10,
                            height: 10,
                            objectFit: "contain",
                          }}
                        ></img>
                      </button>
                    </div>
                  );
                })}
                <select
                  name="operatore"
                  style={styles.menu}
                  value={operatorsList[0]}
                  onChange={(event) => {
                    setOperators(
                      operators.concat(
                        operatorsList.find(
                          (op) => op.id === parseInt(event.target.value)
                        )
                      )
                    );
                  }}
                >
                  <option value={-1}>{"Seleziona operatore"}</option>
                  {operatorsList
                    .filter((opl) => !operators.some((op) => op.id === opl.id))
                    .map((op, key) => {
                      return (
                        <option key={key} value={op.id}>
                          {op.username}
                        </option>
                      );
                    })}
                </select>

                <>
                  <p style={{ color: colors.gray }}>Location</p>
                  <input
                    type="text"
                    style={styles.menu}
                    value={location}
                    onChange={(event) => setLocation(event.target.value)}
                    placeholder={"Spazio"}
                  />
                </>
              </div>
            )}
            {step === 4 && (
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <div style={styles.dataColumn}>
                  <p style={styles.title}>Dati Prenotazione</p>
                  {[
                    { name: "Attività", value: activity?.attivita.nome },
                    ...defaultProps.sort((a, b) => a.order - b.order),
                    { name: "Spazio", value: location },
                  ].map((field, key) => {
                    return (
                      <div
                        key={key}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                          width: "100%",
                        }}
                      >
                        <p
                          style={{
                            flex: 1,
                            margin: 5,
                            marginRight: 10,
                            fontSize: 12,
                            color: colors.gray,
                          }}
                        >
                          {field.name}
                        </p>
                        <p
                          style={{
                            flex: 1,
                            margin: 5,
                            marginRight: 10,
                            fontSize: 12,
                          }}
                        >
                          {field.type === 7
                            ? moment(field.value).format("DD/MM/yyyy")
                            : field.type === 8
                            ? moment(field.value).format("DD/MM/yyyy HH:mm")
                            : field.value === null || field.value === "null"
                            ? "-"
                            : field.value}
                        </p>
                      </div>
                    );
                  })}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 5,
                      width: "100%",
                    }}
                  >
                    <p
                      style={{
                        flex: 1,
                        margin: 5,
                        marginRight: 10,
                        fontSize: 12,
                        color: colors.gray,
                      }}
                    >
                      Operatori
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      {operators.map((o, key) => {
                        return (
                          <p
                            key={key}
                            style={{
                              flex: 1,
                              margin: 5,
                              marginRight: 10,
                              fontSize: 12,
                            }}
                          >
                            {o.username}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div style={styles.dataColumn}>
                  <p style={styles.title}>Dati Aggiuntivi</p>
                  {customProps
                    .sort((a, b) => a.order - b.order)
                    .map((field, key) => {
                      return (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 5,
                            width: "100%",
                          }}
                        >
                          <p
                            style={{
                              flex: 1,
                              margin: 5,
                              marginRight: 10,
                              fontSize: 12,
                              color: colors.gray,
                            }}
                          >
                            {field.name}
                          </p>
                          <p
                            style={{
                              flex: 1,
                              margin: 5,
                              marginRight: 10,
                              fontSize: 12,
                            }}
                          >
                            {field.type === 7
                              ? moment(field.value).format("DD/MM/yyyy")
                              : field.type === 8
                              ? moment(field.value).format("DD/MM/yyyy HH:mm")
                              : field.value === null || field.value === "null"
                              ? "-"
                              : field.value}
                          </p>
                        </div>
                      );
                    })}
                </div>
                <div style={styles.dataColumn}>
                  <p style={styles.title}>Dati Fatturazione</p>
                  {billingProps
                    .sort((a, b) => a.order - b.order)
                    .map((field, key) => {
                      return (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 5,
                            width: "100%",
                          }}
                        >
                          <p
                            style={{
                              flex: 1,
                              margin: 5,
                              marginRight: 10,
                              fontSize: 12,
                              color: colors.gray,
                            }}
                          >
                            {field.name}
                          </p>
                          <p
                            style={{
                              flex: 1,
                              margin: 5,
                              marginRight: 10,
                              fontSize: 12,
                            }}
                          >
                            {field.type === 7
                              ? moment(field.value).format("DD/MM/yyyy")
                              : field.type === 8
                              ? moment(field.value).format("DD/MM/yyyy HH:mm")
                              : field.value === null || field.value === "null"
                              ? "-"
                              : field.value}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {step === 5 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {onModify ? (
                  <p style={{ color: colors.darkgray, margin: 50 }}>
                    {isError
                      ? message
                      : "La modifica alla prenotazione per l'attività " +
                        activity?.nome +
                        " è stata effettuata con successo."}
                  </p>
                ) : (
                  <p style={{ color: colors.darkgray, margin: 50 }}>
                    {isError
                      ? message
                      : "La prenotazione per l'attività " +
                        activity?.nome +
                        " è stata effettuata con successo. Un'email di conferma è stata inviata all'indirizzo del referente."}
                  </p>
                )}
              </div>
            )}
            {step === 6 && (
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <div style={styles.dataColumn}>
                  <p style={styles.title}>Dati Prenotazione</p>
                  {[
                    { name: "Attività", value: activity?.attivita.nome },
                    ...activitySelected.info_prenotazione_default.sort(
                      (a, b) => a.order - b.order
                    ),
                    ...activitySelected.info_gruppo.sort(
                      (a, b) => a.order - b.order
                    ),
                    {
                      name: "Spazio",
                      value: activitySelected.spazio?.nome || "-",
                    },
                  ].map((field, key) => {
                    return (
                      <div
                        key={key}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                          width: "100%",
                        }}
                      >
                        <p
                          style={{
                            flex: 1,
                            margin: 5,
                            marginRight: 10,
                            fontSize: 12,
                            color: colors.gray,
                          }}
                        >
                          {field.name}
                        </p>
                        <p
                          style={{
                            flex: 1,
                            margin: 5,
                            marginRight: 10,
                            fontSize: 12,
                          }}
                        >
                          {field.type === 7
                            ? moment(field.value).format("DD/MM/yyyy")
                            : field.type === 8
                            ? moment(field.value).format("DD/MM/yyyy HH:mm")
                            : field.value === null || field.value === "null"
                            ? "-"
                            : field.value}
                        </p>
                      </div>
                    );
                  })}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 5,
                      width: "100%",
                    }}
                  >
                    <p
                      style={{
                        flex: 1,
                        margin: 5,
                        marginRight: 10,
                        fontSize: 12,
                        color: colors.gray,
                      }}
                    >
                      Operatori
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      {operators.length > 0 ? (
                        operators?.map((o, key) => {
                          return (
                            <p
                              key={key}
                              style={{
                                flex: 1,
                                margin: 5,
                                marginRight: 10,
                                fontSize: 12,
                              }}
                            >
                              {o.username}
                            </p>
                          );
                        })
                      ) : (
                        <p
                          style={{
                            flex: 1,
                            margin: 5,
                            marginRight: 10,
                            fontSize: 12,
                          }}
                        >
                          -
                        </p>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 5,
                      width: "100%",
                    }}
                  >
                    <p
                      style={{
                        flex: 1,
                        margin: 5,
                        marginRight: 10,
                        fontSize: 12,
                        color: colors.gray,
                      }}
                    >
                      Tariffa selezionata
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <p
                        style={{
                          flex: 1,
                          margin: 5,
                          marginRight: 10,
                          fontSize: 12,
                        }}
                      >
                        {activitySelected.tariffa.name +
                          ", " +
                          activitySelected.tariffa.price +
                          "€"}
                      </p>
                    </div>
                  </div>
                </div>
                {activitySelected.info_prenotazione_custom.length > 0 && (
                  <div style={styles.dataColumn}>
                    <p style={styles.title}>Dati Aggiuntivi</p>
                    {activitySelected.info_prenotazione_custom
                      .sort((a, b) => a.order - b.order)
                      .map((field, key) => {
                        return (
                          <div
                            key={key}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginTop: 5,
                              width: "100%",
                            }}
                          >
                            <p
                              style={{
                                flex: 1,
                                margin: 5,
                                marginRight: 10,
                                fontSize: 12,
                                color: colors.gray,
                              }}
                            >
                              {field.name}
                            </p>
                            <p
                              style={{
                                flex: 1,
                                margin: 5,
                                marginRight: 10,
                                fontSize: 12,
                              }}
                            >
                              {field.type === 7
                                ? moment(field.value).format("DD/MM/yyyy")
                                : field.type === 8
                                ? moment(field.value).format("DD/MM/yyyy HH:mm")
                                : field.value === null || field.value === "null"
                                ? "-"
                                : field.value}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                )}
                <div style={styles.dataColumn}>
                  <p style={styles.title}>Dati Fatturazione</p>
                  {activitySelected.info_ente
                    .sort((a, b) => a.order - b.order)
                    .map((field, key) => {
                      return (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 5,
                            width: "100%",
                          }}
                        >
                          <p
                            style={{
                              flex: 1,
                              margin: 5,
                              marginRight: 10,
                              fontSize: 12,
                              color: colors.gray,
                            }}
                          >
                            {field.name}
                          </p>
                          <p
                            style={{
                              flex: 1,
                              margin: 5,
                              marginRight: 10,
                              fontSize: 12,
                            }}
                          >
                            {field.type === 7
                              ? moment(field.value).format("DD/MM/yyyy")
                              : field.type === 8
                              ? moment(field.value).format("DD/MM/yyyy HH:mm")
                              : field.value === null || field.value === "null"
                              ? "-"
                              : field.value}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        )}
        {step !== 6 && !loader && (
          <div style={styles.footer}>
            <button
              onClick={() =>
                onClick(
                  step,
                  activity.id,
                  setStructure,
                  setStep,
                  defaultProps,
                  customProps,
                  billingProps,
                  operators,
                  location,
                  setIsError,
                  setMessage,
                  refresh,
                  closeModal,
                  activitySelected?.id,
                  onModify,
                  setLoader,
                  refreshActivitySelected
                )
              }
              style={{
                ...styles.button,
                opacity:
                  (step === 3 && (operators.length < 1 || location === null)) ||
                  category?.lista_attivita?.length <= 0
                    ? 0.4
                    : 1,
              }}
              disabled={
                (step === 3 && (operators.length < 1 || location === null)) ||
                category?.lista_attivita?.length <= 0
              }
            >
              {step < 4
                ? "AVANTI"
                : step === 5
                ? "OK"
                : onModify
                ? "AGGIORNA"
                : "PRENOTA"}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default CreateActivityReservation;
