import React, { useEffect, useState, useContext } from "react";
import { UserContext, OrgContext } from "../../../../contexts";
import { LanguageContext } from "../../../../containers/language";
import { Badge, Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import arrow from "../../../../img/arrow_back_dark.png";
import modify from "../../../../img/modify_black.png";
import deleteIcon from "../../../../img/delete_darkgray.png";
import show from "../../../../img/show.png";
import upload from "../../../../img/uploadimg_black.png";
import {
  settingsService,
  activitiesService,
  usersService,
} from "../../../../_services";
import ReactLoading from "react-loading";
import { ChromePicker } from "react-color";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Modal from "./modal";
import DeleteSpeaker from "../../../../components/popup/deleteSpeaker";
import EndOperation from "../../../../components/popup/endOperation";
import MailTakenPopup from "./mailTakenPopup";
const localizer = momentLocalizer(moment);

const minTime = new Date();
minTime.setHours(8, 0, 0);
const maxTime = new Date();
maxTime.setHours(20, 0, 0);

function checkOverlappingAvailabilities(allAvailabilities, slot) {
  let availabilities = allAvailabilities.filter((av) => av.id !== slot.id);
  let overlappingStart = availabilities.filter(
    (av) =>
      new Date(av.datetime_inizio) < new Date(slot.start) &&
      new Date(av.datetime_fine) > new Date(slot.start)
  );
  let overlappingEnd = availabilities.filter(
    (av) =>
      new Date(av.datetime_fine) > new Date(slot.end) &&
      new Date(av.datetime_inizio) < new Date(slot.end)
  );

  let fullyOverlapping = availabilities.filter(
    (av) =>
      new Date(slot.start) <= new Date(av.datetime_inizio) &&
      new Date(slot.end) >= new Date(av.datetime_fine)
  );

  if (overlappingStart.length > 0) {
    let maxStart = overlappingStart.reduce((prev, curr) =>
      prev.datetime_fine > curr.datetime_fine ? prev : curr
    );
    slot.start = maxStart.datetime_fine;
  }
  if (overlappingEnd.length > 0) {
    let minEnd = overlappingEnd.reduce((prev, curr) =>
      prev.datetime_inizio < curr.datetime_inizio ? prev : curr
    );
    slot.end = minEnd.datetime_inizio;
  }
  if (
    fullyOverlapping.length > 0 ||
    new Date(slot.start) >= new Date(slot.end)
  ) {
    slot = null;
  }

  return slot;
}

const SpeakerPage = (props) => {
  const colors = useContext(UserContext)?.colors;
  const organisation = useContext(OrgContext).org;
  const me = useContext(UserContext)?.user;
  const org = useContext(OrgContext)?.org;
  const { dictionary } = useContext(LanguageContext);
  const [tabIndex, setTabIndex] = useState(0);

  //We intialiaze this as the isCreation bool because the page's appereance during the creation is the same as while modifying a speaker
  const [isEditing, setIsEditing] = useState(props.isCreation);

  const isCreation = props.isCreation;
  //Bool used during creation to check that all required fields have been compiled
  const [notFilled, setNotFilled] = useState(false);

  var speaker = JSON.parse(JSON.stringify(props.speaker));
  const [tmpImage, setTmpImage] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(null);
  const [newName, setNewName] = useState(speaker.name);
  const [newSurname, setNewSurname] = useState(speaker.surname);
  const [newPhone, setNewPhone] = useState(speaker.mobile);
  const [phoneError, setPhoneError] = useState(false);
  const [newEmail, setNewEmail] = useState(speaker.email);
  const [emailError, setEmailError] = useState(false);
  const [newDescription, setNewDescription] = useState(
    speaker.description || ""
  );
  const [descriptionLength, setDescriptionLength] = useState(
    speaker.description?.length || 1
  );
  const [newColor, setNewColor] = useState(speaker.color);

  const [newPassword, setNewPassword] = useState("");

  //Bool used to show/hide password during the creation of a new speaker
  const [showPassword, setShowPassword] = useState(false);

  const [showEmail, setShowEmail] = useState(
    isCreation ? false : speaker.show_email
  );
  const [showPhone, setShowPhone] = useState(
    isCreation ? false : speaker.show_mobile
  );

  //Bool used to show a warning message above the save button if there are errors
  const [warning, setWarning] = useState(false);

  const [loader, setLoader] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  const [view, setView] = useState("month");
  const [dateSelected, setDateSelected] = useState(new Date());
  const [availabilities, setAvailabilities] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activitiesSelected, setActivitiesSelected] = useState([]);
  const [activitiesList, setActivitiesList] = useState([]);
  const [slot, setSlot] = useState(null);
  const [action, setAction] = useState("create");

  //Flag used to show the popup for confirming the delete of the speaker
  const [deleteSpeaker, setDeleteSpeaker] = useState(false);

  const hiddenFileInput = React.useRef(null);

  //Bool used to show the result of operations
  const [endOperation, setEndOperation] = useState(false);

  //Bool used to check warn when an email has already been used
  const [emailTaken, setEmailTaken] = useState(false);

  //The ID of the speaker role on strapi
  const [role, setRole] = useState("");

  const [imageError, setImageError] = useState(false);

  const CustomToolbar = (toolbar) => {
    const styles = {
      toolbarContainer: {
        margin: 20,
      },
      button: {
        marginLeft: 20,
        marginRight: 20,
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        fontSize: 20,
        cursor: "pointer",
      },
      row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        margin: 0,
        visibility: view === "month" && "hidden",
      },
      back: {
        fontSize: 10,
        marginLeft: 5,
        cursor: "pointer",
      },
      buttonBack: {
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        fontSize: 20,
        cursor: "pointer",
      },
    };

    const goToBack = () => {
      if (view === "month") {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      } else if (view === "day") {
        toolbar.date.setDate(toolbar.date.getDate() - 1);
      }

      toolbar.onNavigate("prev");
    };

    const goToNext = () => {
      if (view === "month") {
        toolbar.date.setDate(1);
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      } else if (view === "day") {
        toolbar.date.setDate(toolbar.date.getDate() + 1);
      }
      toolbar.onNavigate("next");
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span>
          {view === "day" && <b>{date.format("DD")} </b>}
          <b>{date.format("MMMM")}</b>
          <b> {date.format("YYYY")}</b>
        </span>
      );
    };

    return (
      <>
        <div style={styles.row}>
          <button style={styles.buttonBack} onClick={() => setView("month")}>
            &#8249;
          </button>
          <p style={styles.back} onClick={() => setView("month")}>
            Torna al calendario
          </p>
        </div>
        <div style={styles.toolbarContainer}>
          <button style={styles.button} onClick={goToBack}>
            &#8249;
          </button>
          <label>{label()}</label>
          <button style={styles.button} onClick={goToNext}>
            &#8250;
          </button>
        </div>
      </>
    );
  };

  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    const tmpFileUploaded = e.target.files[0];
    if (tmpFileUploaded) {
      if (tmpFileUploaded.size < 1000000) {
        setFileUploaded(tmpFileUploaded);
        setTmpImage(URL.createObjectURL(tmpFileUploaded));
        setImageError(false);
      } else {
        console.log(
          "Dimensione dell'immagine scelta: " +
            (tmpFileUploaded.size / 1000000).toFixed(2) +
            "... MB (superiore a 1 MB)"
        );
        setImageError(true);
      }
    } else {
      setImageError(false);
    }
  };

  const toggleEmailPopup = () => {
    setEmailTaken(!emailTaken);
  };

  const createSpeaker = () => {
    if (newName !== "" && newName !== null) {
      speaker.name = newName;
    }

    if (newSurname !== "" && newSurname !== null) {
      speaker.surname = newSurname;
    }

    if (newPhone !== "" && newPhone !== null) {
      speaker.mobile = newPhone;
    }

    if (newDescription !== "" && newDescription !== null) {
      speaker.description = newDescription;
    }

    if (newEmail !== "" || newEmail !== null) {
      speaker.email = newEmail;
    }

    if (newColor !== "" || newColor !== null) {
      speaker.color = newColor;
    }

    speaker.show_email = showEmail;
    speaker.show_mobile = showPhone;

    let username = newName + " " + newSurname + " Relatore";

    if (!emailError && !phoneError && !notFilled) {
      setLoader(true);

      let user = null;

      usersService
        .createUser(username, newEmail, newPassword, role, org.id, me?.org_rete)
        .then((response) => {
          user = response[response.length - 1];
          activitiesService
            .createSpeaker(speaker, user)
            .then((response) => {
              if (fileUploaded) {
                const formData = new FormData();
                formData.append("files", fileUploaded);
                formData.append("refId", response.id);
                formData.append("ref", "relatori");
                formData.append("field", "photo");

                settingsService.upload(formData).then(() => {
                  setLoader(false);
                  props.getSpeakers();
                  props.setOpenRecord(null);
                  props.setIsCreation(false);
                });
              } else {
                setLoader(false);
                props.getSpeakers();
                props.setOpenRecord(null);
                props.setIsCreation(false);
              }
            })
            .catch((errors) => {
              if (errors.statusCode === 401 || errors.statusCode === 403) {
                this.props.history.push("/");
              } else {
                toggleEmailPopup();
                setEmailTaken(true);
                setLoader(false);
              }
            });
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            this.props.history.push("/");
          } else {
            toggleEmailPopup();
            setEmailTaken(true);
            setLoader(false);
          }
        });
    }
  };

  const updateSpeaker = () => {
    if (newName !== "" && newName !== null) {
      speaker.name = newName;
    }

    if (newSurname !== "" && newSurname !== null) {
      speaker.surname = newSurname;
    }

    if (newPhone !== "" && newPhone !== null) {
      speaker.mobile = newPhone;
    }

    if (newDescription !== "" && newDescription !== null) {
      speaker.description = newDescription;
    }

    if (newEmail !== "" || newEmail !== null) {
      speaker.email = newEmail;
    }

    if (newColor !== "" || newColor !== null) {
      speaker.color = newColor;
    }

    speaker.show_email = showEmail;
    speaker.show_mobile = showPhone;

    if (!emailError && !phoneError) {
      setLoader(true);
      activitiesService.updateSpeaker(speaker).then(() => {
        if (fileUploaded) {
          const formData = new FormData();
          formData.append("files", fileUploaded);
          formData.append("refId", speaker.id);
          formData.append("ref", "relatori");
          formData.append("field", "photo");

          settingsService.upload(formData).then(() => {
            setLoader(false);
            props.getSpeakers();
            setIsEditing(false);
          });
        } else {
          setLoader(false);
          props.getSpeakers();
          setIsEditing(false);
        }
      });
    }
  };

  const handleChangePhone = () => {
    setShowPhone(!showPhone);
  };

  const handleChangeEmail = () => {
    setShowEmail(!showEmail);
  };

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let correct = re.test(String(email).toLowerCase());
    setEmailError(!correct);
  }

  function validatePhone(phone) {
    var numberRex = new RegExp("^[0-9]+$");
    let correct = numberRex.test(phone);
    setPhoneError(!correct);
  }

  const createAvailability = (isRepeated, endingTime) => {
    activitiesService
      .createSpeakerAvailability(
        slot.start,
        slot.end,
        speaker.id,
        activitiesSelected,
        isRepeated,
        endingTime,
        organisation.id
      )
      .then((newAvailabilities) => {
        setAvailabilities(newAvailabilities);
        setShowModal(false);
      });
  };

  const modifyAvailability = () => {
    activitiesService
      .modifySpeakerAvailability(
        slot.id,
        slot.start,
        slot.end,
        activitiesSelected
      )
      .then((newAvailabilities) => {
        setAvailabilities(newAvailabilities);
        setShowModal(false);
      });
  };

  const removeAvailability = () => {
    activitiesService
      .deleteSpeakerAvailability(slot.id)
      .then((newAvailabilities) => {
        setAvailabilities(newAvailabilities);
        setShowModal(false);
      });
  };

  useEffect(() => {
    const getAvailabilities = (speaker, date) => {
      activitiesService
        .getSpeakerAvailabilities(speaker, date)
        .then((response) => {
          setAvailabilities(response);
        });
    };
    const getActivities = (orgId) => {
      activitiesService.getActivities(orgId).then((response) => {
        setActivitiesList(response);
      });
    };

    if (view === "month") {
      if (props.speaker.id) {
        getAvailabilities(props.speaker, dateSelected);
      }
      getActivities(organisation.id);
    }
  }, [dateSelected, organisation.id, view, props.speaker]);

  useEffect(() => {
    const compareDates = (first, second) => {
      return (
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()
      );
    };

    if (view === "day") {
      let tmpMax = 20;

      let tmpMin = 8;

      availabilities.forEach((element) => {
        if (
          compareDates(new Date(element.datetime_inizio), dateSelected) &&
          (new Date(element.datetime_inizio).getHours() > 20 ||
            new Date(element.datetime_fine).getHours() > 20) &&
          new Date(element.datetime_fine).getHours() > tmpMax
        ) {
          tmpMax = new Date(element.datetime_fine).getHours();
        }

        if (
          compareDates(new Date(element.datetime_inizio), dateSelected) &&
          (new Date(element.datetime_inizio).getHours() < 8 ||
            new Date(element.datetime_fine).getHours() > 8) &&
          new Date(element.datetime_fine).getHours() < tmpMin
        ) {
          tmpMin = new Date(element.datetime_inizio).getHours();
        }
      });

      maxTime.setHours(tmpMax, 0, 0);
      minTime.setHours(tmpMin, 0, 0);
    }
  }, [dateSelected, view, availabilities]);

  useEffect(() => {
    usersService.getRoles().then((response) => {
      response.roles.forEach((ruolo) => {
        if (ruolo.name === "Relatore") {
          setRole(ruolo.id);
        }
      });
    });

    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const toggleDeleteSpeaker = () => {
    setDeleteSpeaker(!deleteSpeaker);
  };

  const toggleEndOperation = () => {
    setEndOperation(!endOperation);
  };

  const handleDelete = () => {
    activitiesService
      .deleteSpeaker(speaker.id)
      .then(() => {
        usersService
          .deleteUser(speaker.user.id)
          .then(() => {
            props.getSpeakers();
            props.setIsCreation(false);
            props.setOpenRecord(null);
          })
          .catch(() => {
            setDeleteSpeaker(false);
            toggleEndOperation();
          });
      })
      .catch(() => {
        setDeleteSpeaker(false);
        toggleEndOperation();
      });
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      justifyContent: "flex-start",
      overflow: "scroll",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "95%",
      alignItems: "center",
      overflowX: "scroll",
    },
    rowTop: {
      alignItems: "start",
      color: colors?.primary,
      width: "100%",
    },
    rowBottom: {
      display: "flex",
      justifyContent: "center",
      width: "90%",
      height: "100%",
      overflow: "scroll",
    },
    subcontainer: {
      width: "100%",
      paddingBottom: 20,
      paddingTop: 20,
    },
    goBackButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    arrow: {
      height: 13,
      objectFit: "contain",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      paddingBottom: "50px",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "center",
    },
    avatar: {
      backgroundColor: colors.gray,
      width: "250px",
      height: "250px",
    },
    label: {
      color: colors?.primary,
      fontSize: 15,
      textAlign: "left",
    },
    textInput: {
      outline: "none",
      padding: 10,
      textAlign: "left",
      width: "80%",
      border: "1px solid lightgray",
      borderRadius: 5,
    },
    saveButton: {
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: colors.darkgray,
      margin: 30,
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 15,
    },
    credentialsField: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
    },
  };

  return (
    <div style={styles.container}>
      {deleteSpeaker && (
        <DeleteSpeaker
          name={newName}
          closeModal={toggleDeleteSpeaker}
          saveData={() => handleDelete()}
          colors={colors}
        />
      )}
      {endOperation && (
        <EndOperation
          flag={false}
          operation={2}
          closeModal={toggleEndOperation}
          colors={colors}
        />
      )}
      {emailTaken && (
        <MailTakenPopup closeModal={toggleEmailPopup} colors={colors} />
      )}
      <div style={styles.body}>
        {!isCreation && (
          <div style={styles.rowTop}>
            <Tabs
              value={tabIndex}
              variant="fullWidth"
              TabIndicatorProps={{ style: { background: colors?.secondary } }}
              onChange={(event, newValue) => {
                setIsEditing(false);
                setTabIndex(newValue);
              }}
            >
              <Tab label={"Info"} />
              <Tab label={dictionary.availability} />
            </Tabs>
          </div>
        )}
        <div style={styles.rowBottom}>
          {tabIndex === 0 ? (
            <div style={styles.subcontainer}>
              <div style={{ ...styles.row, justifyContent: "space-between" }}>
                <button
                  style={styles.goBackButton}
                  onClick={() => {
                    props.setIsCreation(false);
                    props.setOpenRecord(null);
                  }}
                  disabled={loader}
                >
                  <img src={arrow} style={styles.arrow} alt={"Go back"} />
                  <p
                    style={{
                      fontSize: 12,
                      color: colors.darkgray,
                      margin: 0,
                      marginLeft: 5,
                      fontWeight: "600",
                    }}
                  >
                    Indietro
                  </p>
                </button>
                {!isEditing && (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <button
                      style={styles.goBackButton}
                      onClick={() => setIsEditing(!isEditing)}
                    >
                      <img src={modify} style={styles.arrow} alt={"Go back"} />
                      <p
                        style={{
                          fontSize: 12,
                          color: colors.darkgray,
                          margin: 0,
                          marginLeft: 5,
                          fontWeight: "600",
                        }}
                      >
                        Modifica
                      </p>
                    </button>
                    <button
                      style={styles.goBackButton}
                      onClick={() => setDeleteSpeaker(!deleteSpeaker)}
                    >
                      <img
                        src={deleteIcon}
                        style={styles.arrow}
                        alt={"Delete"}
                      />
                      <p
                        style={{
                          fontSize: 12,
                          color: colors.darkgray,
                          margin: 0,
                          marginLeft: 5,
                          fontWeight: "600",
                        }}
                      >
                        Elimina
                      </p>
                    </button>
                  </div>
                )}
              </div>
              <div style={styles.row}>
                <div style={{ paddingRight: "50px" }}>
                  {isEditing ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      badgeContent={
                        <>
                          <img
                            alt="Upload img"
                            src={upload}
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "5px",
                              backgroundColor: colors.white,
                              cursor: "pointer",
                              padding: "1px",
                            }}
                            onClick={handleClick}
                          />
                          <input
                            type="file"
                            accept=".png,.jpg,.jpeg"
                            ref={hiddenFileInput}
                            onChange={handleChange}
                            style={{ display: "none" }}
                          />
                        </>
                      }
                    >
                      <Avatar
                        alt="Profile Picture"
                        src={
                          tmpImage !== null
                            ? tmpImage
                            : speaker.photo
                            ? speaker.photo.url
                            : ""
                        }
                        style={styles.avatar}
                        onClick={handleClick}
                      />
                      <input
                        type="file"
                        accept=".png,.jpg,.jpeg"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{ display: "none", cursor: "pointer" }}
                      />
                    </Badge>
                  ) : (
                    <Avatar
                      alt="Profile Picture"
                      src={
                        tmpImage !== null
                          ? tmpImage
                          : speaker.photo
                          ? speaker.photo.url
                          : ""
                      }
                      style={styles.avatar}
                      sx={{ width: "300px", height: "300px" }}
                    />
                  )}
                </div>
                <div style={styles.column}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 3,
                      }}
                    >
                      <p style={styles.label}>{dictionary.name}</p>
                      <input
                        type="text"
                        name="speakerName"
                        autoComplete="on"
                        value={newName}
                        disabled={!isEditing}
                        onChange={(event) => {
                          setNewName(event.target.value);
                        }}
                        style={styles.textInput}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 3,
                      }}
                    >
                      <p style={styles.label}>{dictionary.surname}</p>
                      <input
                        type="text"
                        name="speakerSurname"
                        autoComplete="on"
                        value={newSurname}
                        disabled={!isEditing}
                        onChange={(event) => {
                          setNewSurname(event.target.value);
                        }}
                        style={styles.textInput}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 3,
                      }}
                    >
                      {!notFilled ? (
                        <p style={styles.label}>{dictionary.phone}</p>
                      ) : phoneError ? (
                        <p
                          style={{ ...styles.label, color: colors?.highlight }}
                        >
                          {" "}
                          Numero non valido{" "}
                        </p>
                      ) : (
                        <p style={styles.label}>{dictionary.phone}</p>
                      )}
                      <input
                        type="text"
                        name="speakerPhone"
                        autoComplete="on"
                        value={newPhone}
                        disabled={!isEditing}
                        onChange={(event) => {
                          setNewPhone(event.target.value);
                          validatePhone(event.target.value);
                        }}
                        style={styles.textInput}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 3,
                      }}
                    >
                      {!notFilled ? (
                        <p style={styles.label}>Email</p>
                      ) : emailError ? (
                        <p
                          style={{ ...styles.label, color: colors?.highlight }}
                        >
                          Email non valida
                        </p>
                      ) : (
                        <p style={styles.label}>Email</p>
                      )}
                      <input
                        type="text"
                        name="speakerEmail"
                        autoComplete="on"
                        value={newEmail}
                        disabled={!isEditing}
                        onChange={(event) => {
                          setNewEmail(event.target.value);
                          validateEmail(event.target.value);
                        }}
                        style={styles.textInput}
                      />
                    </div>
                  </div>
                  <div style={styles.row}>
                    <div
                      style={{
                        display: "flex",
                        flex: 3,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginLeft: "5px",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={handleChangePhone}
                          checked={showPhone}
                          disabled={!isEditing}
                        />
                        <p
                          style={{
                            ...styles.label,
                            width: "100%",
                            marginLeft: 10,
                            color: colors?.darkgray,
                          }}
                        >
                          {dictionary.showPhone}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: 3,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginLeft: "5px",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={handleChangeEmail}
                          checked={showEmail}
                          disabled={!isEditing}
                        />
                        <p
                          style={{
                            ...styles.label,
                            width: "100%",
                            marginLeft: 10,
                            color: colors?.darkgray,
                          }}
                        >
                          {dictionary.showEmail}
                        </p>
                      </div>
                    </div>
                  </div>
                  {isEditing && imageError && (
                    <p style={{ ...styles.label, color: colors?.highlight }}>
                      {
                        "L'immagine scelta non può eccedere la dimensione di 1MB"
                      }
                    </p>
                  )}
                </div>
              </div>
              <div style={{ ...styles.row, paddingBottom: 10 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "76%",
                  }}
                >
                  <p style={styles.label}>{dictionary.presentation}</p>
                  <textarea
                    value={newDescription}
                    disabled={!isEditing}
                    onChange={(event) => {
                      setNewDescription(event.target.value);
                      setDescriptionLength(event.target.value.length);
                    }}
                    style={{
                      ...styles.textInput,
                      width: "93%",
                      resize: "none",
                      fontFamily: "inherit",
                    }}
                    rows={Math.ceil(descriptionLength / 100)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 3,
                  }}
                >
                  <p style={styles.label}>{dictionary.color}</p>
                  {isEditing ? (
                    <ChromePicker
                      color={newColor || "#fff"}
                      onChange={(e) => setNewColor(e.hex)}
                    />
                  ) : (
                    <div
                      style={{
                        backgroundColor: colors.darkgray,
                        height: 22,
                        width: 22,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: newColor,
                          height: 20,
                          width: 20,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <p style={{ ...styles.label, fontSize: 20, marginBottom: 5 }}>
                {dictionary.credentials}
              </p>
              <div style={{ ...styles.row, paddingBottom: 10 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 3,
                  }}
                >
                  <p style={styles.label}>{dictionary.username}</p>
                  <div style={styles.credentialsField}>
                    <input
                      type={"text"}
                      name="username"
                      autoComplete="on"
                      value={newEmail}
                      disabled={true}
                      style={styles.textInput}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 3,
                  }}
                >
                  <p style={styles.label}>Password</p>
                  <div style={styles.credentialsField}>
                    <input
                      type={isCreation && showPassword ? "text" : "password"}
                      name="password"
                      value={!isCreation ? "password" : newPassword}
                      disabled={!isCreation}
                      style={styles.textInput}
                      min={0}
                      onChange={(event) => {
                        setNewPassword(event.target.value);
                      }}
                    />
                    {newPassword !== "" && isCreation && (
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowPassword(!showPassword);
                        }}
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                          outline: "none",
                          cursor: "pointer",
                          marginLeft: -50,
                        }}
                      >
                        <img
                          alt="Show password"
                          src={show}
                          style={{ width: 20, objectFit: "contain" }}
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {isEditing && (
                <div style={{ ...styles.column, alignItems: "center" }}>
                  {warning && (
                    <p style={{ ...styles.label, color: colors?.highlight }}>
                      {dictionary.checkErrors}
                    </p>
                  )}
                  {notFilled && (
                    <p style={{ ...styles.label, color: colors?.highlight }}>
                      {isCreation
                        ? dictionary.notFilledSpeakerCreation
                        : dictionary.notFilledSpeaker}
                    </p>
                  )}
                  <div style={styles.row}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {!loader && (
                        <button
                          style={{
                            ...styles.saveButton,
                            backgroundColor: colors.darkgray,
                          }}
                          onClick={() => {
                            //We declare a duplicate of the bool to avoid errors caused by the delay of the state update. We don't want to enter the last if in case of errors.
                            var tmpWarning = warning;
                            var tmpNotFilled = notFilled;

                            if (emailError || phoneError) {
                              tmpWarning = true;
                              setWarning(true);
                            } else {
                              tmpWarning = false;
                              setWarning(false);
                            }
                            if (
                              isCreation &&
                              (newName === "" ||
                                newSurname === "" ||
                                newPhone === "" ||
                                newEmail === "" ||
                                newPassword === "" ||
                                fileUploaded === null)
                            ) {
                              tmpNotFilled = true;
                              setNotFilled(true);
                            } else {
                              tmpNotFilled = false;
                              setNotFilled(false);
                            }

                            if (!tmpWarning && !tmpNotFilled) {
                              if (isCreation) {
                                createSpeaker();
                              } else {
                                updateSpeaker();
                              }
                            }
                          }}
                        >
                          <p
                            style={{
                              fontSize: 12,
                              color: colors.white,
                              margin: 0,
                              fontWeight: "600",
                            }}
                          >
                            {dictionary.save}
                          </p>
                        </button>
                      )}
                      {loader && (
                        <ReactLoading
                          type={"spinningBubbles"}
                          color={colors?.primary}
                          height={50}
                          width={50}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div style={styles.subcontainer}>
              {view === "month" && (
                <div
                  style={{
                    ...styles.row,
                    justifyContent: "space-between",
                    paddingBottom: "",
                  }}
                >
                  <button
                    style={styles.goBackButton}
                    onClick={() => {
                      props.setIsCreation(false);
                      props.setOpenRecord(null);
                    }}
                    disabled={loader}
                  >
                    <img src={arrow} style={styles.arrow} alt={"Go back"} />
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.darkgray,
                        margin: 0,
                        marginLeft: 5,
                        fontWeight: "600",
                      }}
                    >
                      Indietro
                    </p>
                  </button>
                </div>
              )}
              <div
                style={{
                  backgroundColor: "#FFFFFF",
                  display: "flex",
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    height: "90%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <Calendar
                    date={moment(dateSelected).toDate()}
                    localizer={localizer}
                    events={availabilities}
                    titleAccessor={(event) => {
                      if (view === "day") {
                        let activities = event.attivitas.map((act) => {
                          return act.nome;
                        });
                        return activities.toString().replace(",", ", ");
                      } else {
                        if (event.attivitas.length > 1) {
                          return event.attivitas.length + " attività";
                        } else {
                          return event.attivitas[0].nome;
                        }
                      }
                    }}
                    startAccessor={(event) => {
                      return new Date(event.datetime_inizio);
                    }}
                    endAccessor={(event) => {
                      return new Date(event.datetime_fine);
                    }}
                    style={{
                      height: "95%",
                      width: "100%",
                    }}
                    components={{
                      toolbar: CustomToolbar,
                    }}
                    view={view}
                    defaultView={view}
                    min={minTime}
                    max={maxTime}
                    onNavigate={(date) => setDateSelected(new Date(date))}
                    popup={true}
                    onSelectEvent={(event) => {
                      setDateSelected(new Date(event.datetime_inizio));
                      if (view === "month") {
                        setView("day");
                      } else {
                        if (new Date(event.datetime_inizio) >= new Date()) {
                          setAction("modify");
                          setShowModal(true);
                          setSlot(event);
                          setActivitiesSelected(event.attivitas);
                        }
                      }
                      //setAvailabilitySelected(event);
                    }}
                    onDrillDown={(date) => {
                      if (new Date(date) >= new Date()) {
                        if (view === "month") {
                          setView("day");
                        }
                        setDateSelected(date);
                      }
                    }}
                    selectable={view === "day"}
                    onSelectSlot={(slot) => {
                      if (new Date(slot.start) >= new Date()) {
                        setAction("create");
                        let newSlot = checkOverlappingAvailabilities(
                          availabilities,
                          slot
                        );
                        if (newSlot) {
                          setSlot(newSlot);
                          setShowModal(true);
                        }
                      }
                    }}
                    dayPropGetter={(date) => {
                      let dayStyle = {
                        backgroundColor:
                          moment(date).add(1, "days") < moment()
                            ? colors?.verylightgray
                            : "transparent",
                      };
                      return {
                        className: "",
                        style: dayStyle,
                      };
                    }}
                    eventPropGetter={(event, start, end, isSelected) => {
                      let newStyle = {
                        backgroundColor: colors?.green,
                        color: colors?.darkgray,
                        borderRadius: 5,
                        outline: "none",
                        borderTop: "1px solid",
                        borderBottom: "1px solid",
                        borderRightWidth: width > 800 ? "8px solid" : "0",
                        borderLeftWidth: width > 800 ? "8px solid" : "0",
                        borderColor: colors?.green,
                        fontSize: 13,
                      };
                      return {
                        className: "",
                        style: newStyle,
                      };
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <Modal
          action={action}
          slot={slot}
          activitiesList={activitiesList}
          activitiesSelected={activitiesSelected}
          setActivitiesSelected={setActivitiesSelected}
          setShowModal={setShowModal}
          create={createAvailability}
          modify={modifyAvailability}
          remove={removeAvailability}
          checkOverlappingAvailabilities={(slot) =>
            checkOverlappingAvailabilities(availabilities, slot)
          }
        />
      )}
    </div>
  );
};

export default SpeakerPage;
