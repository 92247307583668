import React, { useState, useContext } from "react";
import trashsec from "./../../../img/delete_secondary.png";
import topay from "./../../../img/sale_topay.png";
import payd from "./../../../img/sale_euro.png";
//import pen from './../img/modify_secondary.png'
import { UserContext } from "../../../contexts";
import moment from "moment";
import { reservationsService } from "../../../_services";
import ReactLoading from "react-loading";

/*function deleteChild(list, id){
    return list.filter((child) => child.id !== id)
}
*/

function resendReservation(id, setSendConfirmed, setMessage, setIsLoading) {
  setIsLoading(true);
  reservationsService.sendEmailEvent(id).then((response) => {
    if (response.status === true) {
      setSendConfirmed(true);
    } else {
      setSendConfirmed(false);
    }
    setMessage(response.message);
    setIsLoading(false);
  });
}

const ReservationInfo = ({
  reservation,
  eventSelected,
  idSelected,
  setIdSelected,
  onDeleteReservation,
  onDeleteWLReservation,
  togglePaymentPopup,
  isWaitingList,
}) => {
  const [openInfo, setOpenInfo] = useState(false);
  const [openModify] = useState(false);
  const [number, setNumber] = useState(reservation.numero_partecipanti);
  const [errorNumber, setErrorNumber] = useState(false);
  const [sendConfirmed, setSendConfirmed] = useState(null);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const colors = useContext(UserContext)?.colors;

  React.useEffect(() => {
    setNumber(reservation.numero_partecipanti);
  }, [reservation]);

  React.useEffect(() => {
    if (openInfo === false) {
      setSendConfirmed(null);
      setMessage("");
    } else {
      setIdSelected(reservation.id);
    }
  }, [openInfo, reservation, setIdSelected]);

  const styles = {
    reservation: {
      flexDirection: "row",
      display: "flex",
      width: "100%",
      height: 40,
      alignItems: "center",
      justifyContent: "space-around",
      cursor: "pointer",
    },
    contacts: {
      flexDirection: "column",
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    info: {
      flex: 1,
      flexDirection: "row",
      display: "flex",
      alignItems: "center",
      fontSize: 14,
      marginLeft: 15,
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      cursor: "pointer",
    },
    textInput: {
      borderRadius: 5,
      border: 0,
      outline: "none",
      textAlign: "center",
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
      width: 30,
    },
  };
  return (
    <div
      style={{
        width: "100%",
        marginBottom: 5,
        backgroundColor:
          (idSelected === reservation.id ||
            (!reservation.flag_visita && openInfo)) &&
          colors?.quaternary,
      }}
    >
      <div
        style={{ ...styles.reservation }}
        onClick={() => setOpenInfo(!openInfo)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "70%",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <p style={{ ...styles.info, flex: 2 }}>
            {reservation.flag_visita
              ? moment(reservation.ora_inizio).format("HH:mm")
              : reservation.cognome_partecipante +
                " " +
                reservation.nome_partecipante}
          </p>
          <div style={styles.info}>
            {openModify && !reservation.bambini ? (
              <input
                type="number"
                name="number"
                value={number}
                onChange={(event) => {
                  if (
                    eventSelected &&
                    eventSelected.posti_prenotati +
                      parseInt(event.target.value) >
                      eventSelected.posti_totali
                  ) {
                    setErrorNumber(true);
                    setNumber("");
                  } else {
                    setNumber(parseInt(event.target.value));
                    setErrorNumber(false);
                  }
                }}
                placeholder="Numero partecipanti"
                style={{ ...styles.textInput }}
              />
            ) : (
              <p>{"x" + number}</p>
            )}
          </div>
        </div>
        {errorNumber && (
          <p style={styles.errorMessage}>
            Solo {eventSelected.posti_totali - eventSelected.posti_prenotati}{" "}
            posti disponibili.
          </p>
        )}
        {
          <div style={{ marginRight: 10 }}>
            {!openModify &&
              !isWaitingList &&
              reservation.pagato &&
              !reservation.flag_visita && (
                //eventSelected.biglietti?.length > 0 && (
                <button
                  style={styles.button}
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePaymentPopup(reservation, eventSelected?.id);
                  }}
                >
                  <img alt="Payment icon" src={payd} style={{ height: 15 }} />
                </button>
              )}
            {!openModify &&
              !isWaitingList &&
              !reservation.pagato &&
              !reservation.flag_visita && (
                //eventSelected.biglietti?.length > 0 && (
                <button
                  style={styles.button}
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePaymentPopup(reservation, eventSelected?.id);
                  }}
                >
                  <img alt="Payment icon" src={topay} style={{ height: 15 }} />
                </button>
              )}
            {!openModify && (
              <button
                style={styles.button}
                onClick={(e) => {
                  e.stopPropagation();
                  isWaitingList
                    ? onDeleteWLReservation(reservation, eventSelected?.id)
                    : onDeleteReservation(reservation, eventSelected?.id);
                }}
              >
                <img
                  alt="Delete Event Button"
                  src={trashsec}
                  style={{ height: 15 }}
                />
              </button>
            )}
            {/*<button style={styles.button}
                        onClick={
                            (e) => {
                                e.stopPropagation()
                                if (openModify === true) {
                                    onModifyReservation(reservation.id, eventSelected?.id, number)
                                }
                                if(reservation.bambini?.length > 0){
                                    setOpenInfo(true)
                                    setHide(true)
                                }
                                setOpenModify(!openModify)
                            }
                        }>
                        <img alt="Modify Event Button" src={pen} style={{ height: 15 }} />
                    </button>  */}
          </div>
        }
      </div>
      {openInfo && (
        <div style={styles.contacts}>
          {reservation.flag_visita && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
              }}
            >
              <p style={{ fontSize: 10, flex: 1 }}>Nome</p>
              <p style={{ fontSize: 13, flex: 2 }}>
                {reservation.nome_partecipante +
                  " " +
                  reservation.cognome_partecipante}
              </p>
            </div>
          )}
          {reservation.codice && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
              }}
            >
              <p style={{ fontSize: 10, flex: 1 }}>Codice</p>
              <p style={{ fontSize: 13, flex: 2 }}>{reservation.codice}</p>
            </div>
          )}
          {reservation.numero_telefono && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
              }}
            >
              <p style={{ fontSize: 10, flex: 1 }}>Telefono</p>
              <p style={{ fontSize: 13, flex: 2 }}>
                {reservation.numero_telefono}
              </p>
            </div>
          )}
          {reservation.email && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
              }}
            >
              <p style={{ fontSize: 10, flex: 1 }}>Email</p>
              <p style={{ fontSize: 13, flex: 2 }}>{reservation.email}</p>
            </div>
          )}
          {reservation.bambini?.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <p style={{ fontSize: 10, flex: 1 }}>Bambini</p>
                <div style={{ fontSize: 13, flex: 2 }}>
                  {reservation.bambini.map((child, key) => {
                    return (
                      <div
                        key={key}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ fontSize: 13, flex: 3 }}>
                          {child.nome_bambino + " " + child.cognome_bambino}
                        </p>
                        <p style={{ fontSize: 13, flex: 2 }}>
                          {child.eta_bambino} anni
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          {!reservation.flag_visita && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
              }}
            >
              <p style={{ fontSize: 10, flex: 1 }}>Pagato</p>
              <p style={{ fontSize: 13, flex: 2 }}>
                {reservation.pagato ? "Si" : "No"}
              </p>
            </div>
          )}
          {!reservation.flag_visita && reservation.pagato && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
              }}
            >
              <p style={{ fontSize: 10, flex: 1 }}>
                Metodo <br />
                di pagamento
              </p>
              <p style={{ fontSize: 13, flex: 2 }}>
                {reservation.metodo_pagamento}
              </p>
            </div>
          )}
          {isWaitingList === false &&
            (isLoading ? (
              <div
                style={{
                  // width: "100vw",
                  // height: "100vh",
                  marginTop: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={colors?.darkgray}
                  height={20}
                  width={20}
                />
              </div>
            ) : (
              <button
                style={{
                  ...styles.button,
                  marginTop: 10,
                  borderRadius: 5,
                  backgroundColor: colors?.secondary,
                  color: colors?.white,
                  padding: 10,
                }}
                onClick={() =>
                  resendReservation(
                    reservation.id,
                    setSendConfirmed,
                    setMessage,
                    setIsLoading
                  )
                }
                disabled={sendConfirmed !== null}
              >
                {sendConfirmed === true
                  ? "Email inviata!"
                  : sendConfirmed === false
                  ? "Errore"
                  : "Invia e-mail prenotazione"}
              </button>
            ))}
          <p style={{ fontSize: 12, color: colors?.darkgray }}>{message}</p>
        </div>
      )}
    </div>
  );
};

export default ReservationInfo;
