import config from "../config";
import { handleResponse, authHeader } from "../_helpers";
import moment from "moment";

export const eventsService = {
  getEventCategories,
  getPastEvents,
  getActivityCategories,
  createEvent,
  createActivity,
  modifyEvent,
  modifyActivity,
  modifyActivityRes,
  modifySpeakerRes,
  modifyModel,
  modifyTickets,
  createTicketsType,
  deleteEventCategory,
  disableEventCategory,
  enableEventCategory,
  deleteActivityCategory,
  deleteSchemaEvent,
  deleteEvent,
  deleteActivity,
  deleteTicketsType,
  createCategory,
  createActivityCategory,
  modifyCategory,
  modifyActivityCategory,
  modifyTicketsType,
  retrieveImg,
  getEvents,
  getEventsInPeriod,
  modifyChildEventDate,
  getAdditionalInfo,
  getTicketsType,
  getActivitiesRes,
  getConfirmedActivitiesRes,
  countConfirmedActivitiesRes,
  getActivities,
  getBookingRequests,
  countBookingRequests,
  getSpeakerBookingRequests,
  countSpeakerBookingRequests,
  getActivity,
  getVisits,
  getActivityStructure,
  getHistogram,
  getClosingDay,
  getSingleEvent,
  getEventCategory,
  getEventChild,
  getEventChildPerCategory,
  getGroup,
};

function getEventCategory(name) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/categorie_eventis/?nome_categoria=${name}`,
    requestOptions
  ).then(handleResponse);
}

function getEventChildPerCategory(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/eventi_childs/?main_event=${id}`,
    requestOptions
  ).then(handleResponse);
}

function getEventChild(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/eventi_childs/?id=${id}`,
    requestOptions
  ).then(handleResponse);
}

function getEventCategories() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/categorie_eventis/get_all`,
    requestOptions
  ).then(handleResponse);
}

function getPastEvents() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/categorie_eventis/archive`,
    requestOptions
  ).then(handleResponse);
}

function getActivityCategories() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/categorie_attivita/get_all`,
    requestOptions
  ).then(handleResponse);
}

function getEvents(date) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/eventi_childs/eventiMonth/${date}`,
    requestOptions
  ).then(handleResponse);
}

function getEventsInPeriod(orgId) {
  const startD = moment(new Date("April 06, 2024").setHours(0, 0, 0, 0)).format(
    "YYYY-MM-DD"
  );
  const endD = moment(new Date("August 01, 2024").setHours(0, 0, 0, 0)).format(
    "YYYY-MM-DD"
  );
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${
      config.api.url
    }/eventi_childs/?_limit=${-1}&organisation=${orgId}&ora_inizio_gte=${startD}&ora_fine_lte=${endD}`,
    requestOptions
  ).then(handleResponse);
}

function modifyChildEventDate(id, ora_inizio, ora_fine) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      ora_inizio: ora_inizio,
      ora_fine: ora_fine,
    }),
  };

  return fetch(
    //substitute with:
    // `https://emma4culture.com/api/eventi_childs/updateFix/${id}`,
    `${config.api.url}/eventi_childs/updateFix/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function getAdditionalInfo(eventId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/eventi_childs/visitor_additional_info/${eventId}`,
    requestOptions
  ).then(handleResponse);
}

function getTicketsType(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/biglietti-eventis/tickets/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getActivitiesRes(date) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/prenotazioni_attivita/month/${date}`,
    requestOptions
  ).then(handleResponse);
}

function getConfirmedActivitiesRes(
  start,
  limit,
  invoice,
  searchKey,
  ordine,
  ascendente,
  orgId
) {
  var qs = require("qs");

  const _start = start === undefined ? 0 : start;
  const _limit = limit === undefined ? -1 : limit;
  const _invoice = invoice === undefined ? false : invoice;
  const _searchKey = searchKey === undefined ? "" : searchKey;
  const _confirmed = "confirmed";

  ordine = encodeURIComponent(ordine);

  const requestOptions = { method: "GET", headers: authHeader() };
  if (_searchKey === "") {
    return fetch(
      `${config.api.url}/prenotazioni_attivita/?organisation=${orgId}&_start=${_start}&_limit=${_limit}&fattura_emessa=${_invoice}&speaker_feedback=${_confirmed}&org_feedback=${_confirmed}&_ordine=${ordine}&_ascendente=${ascendente}`,
      requestOptions
    ).then(handleResponse);
  } else {
    const query = qs.stringify({
      _where: {
        _or: [{ nome_contains: _searchKey }, { cognome_contains: _searchKey }],
      },
    });
    return fetch(
      `${config.api.url}/prenotazioni_attivita/?organisation=${orgId}&_start=${_start}&_limit=${_limit}&fattura_emessa=${_invoice}&speaker_feedback=${_confirmed}&org_feedback=${_confirmed}&_ordine=${ordine}&_ascendente=${ascendente}&${query}`,
      requestOptions
    ).then(handleResponse);
  }
}

function countConfirmedActivitiesRes(invoice, searchKey, orgId) {
  const _invoice = invoice === undefined ? false : invoice;

  var req = ``;

  var qs = require("qs");
  const requestOptions = { method: "GET", headers: authHeader() };
  const _searchKey = searchKey;
  const _confirmed = "confirmed";

  if (_searchKey === "") {
    req = `${config.api.url}/prenotazioni_attivita/count?organisation=${orgId}&speaker_feedback=${_confirmed}&org_feedback=${_confirmed}&fattura_emessa=${_invoice}`;
  } else {
    const query = qs.stringify({
      _where: {
        _or: [{ nome_contains: _searchKey }, { cognome_contains: _searchKey }],
      },
    });

    req = `${config.api.url}/prenotazioni_attivita/count?organisation=${orgId}&speaker_feedback=${_confirmed}&org_feedback=${_confirmed}&fattura_emessa=${_invoice}&${query}`;
  }

  return fetch(req, requestOptions).then(handleResponse);
}

function getActivity(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/attivita/${id}`, requestOptions).then(
    handleResponse
  );
}

function getActivities() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/attivita`, requestOptions).then(
    handleResponse
  );
}

function getBookingRequests(
  start,
  limit,
  invoice,
  searchKey,
  ordine,
  ascendente,
  orgId
) {
  var qs = require("qs");

  const _start = start === undefined ? 0 : start;
  const _limit = limit === undefined ? -1 : limit;
  const _invoice = invoice === undefined ? false : invoice;
  const _searchKey = searchKey === undefined ? "" : searchKey;

  var req = ``;

  const invoiceQuery = qs.stringify({
    _where: {
      // even if the org has not confirmed/refused yet (i.e. it is pending), if the speaker has already refused, it is considered "processed"
      _or: [{ org_feedback_ne: "pending" }, { speaker_feedback_eq: "refused" }],
    },
  });

  ordine = encodeURIComponent(ordine);

  const requestOptions = { method: "GET", headers: authHeader() };
  if (_searchKey === "") {
    if (_invoice) {
      req = `${config.api.url}/prenotazioni_attivita/?organisation=${orgId}&_start=${_start}&_limit=${_limit}&_ordine=${ordine}&_ascendente=${ascendente}&${invoiceQuery}`;
    } else {
      req = `${config.api.url}/prenotazioni_attivita/?organisation=${orgId}&_start=${_start}&_limit=${_limit}&_ordine=${ordine}&_ascendente=${ascendente}&org_feedback_eq=pending&speaker_feedback_ne=refused`;
    }
  } else {
    const query = qs.stringify({
      _where: {
        _or: [{ nome_contains: _searchKey }, { cognome_contains: _searchKey }],
      },
    });

    if (_invoice) {
      req = `${config.api.url}/prenotazioni_attivita/?organisation=${orgId}&_start=${_start}&_limit=${_limit}&_ordine=${ordine}&_ascendente=${ascendente}&${invoiceQuery}&${query}`;
    } else {
      req = `${config.api.url}/prenotazioni_attivita/?organisation=${orgId}&_start=${_start}&_limit=${_limit}&_ordine=${ordine}&_ascendente=${ascendente}&org_feedback_eq=pending&speaker_feedback_ne=refused&${query}`;
    }
  }

  return fetch(req, requestOptions).then(handleResponse);
}

function countBookingRequests(invoice, searchKey, orgId) {
  const _invoice = invoice === undefined ? false : invoice;

  var req = ``;

  var qs = require("qs");
  const requestOptions = { method: "GET", headers: authHeader() };
  const _searchKey = searchKey;

  const invoiceQuery = qs.stringify({
    _where: {
      // even if the org has not confirmed/refused yet (i.e. it is pending), if the speaker has already refused, it is considered "processed"
      _or: [{ org_feedback_ne: "pending" }, { speaker_feedback_eq: "refused" }],
    },
  });

  if (_searchKey === "") {
    if (_invoice) {
      req = `${config.api.url}/prenotazioni_attivita/count?organisation=${orgId}&${invoiceQuery}`;
    } else {
      req = `${config.api.url}/prenotazioni_attivita/count?organisation=${orgId}&org_feedback_eq=pending&speaker_feedback_ne=refused`;
    }
  } else {
    const query = qs.stringify({
      _where: {
        _or: [{ nome_contains: _searchKey }, { cognome_contains: _searchKey }],
      },
    });

    if (_invoice) {
      req = `${config.api.url}/prenotazioni_attivita/count?organisation=${orgId}&${invoiceQuery}&${query}`;
    } else {
      req = `${config.api.url}/prenotazioni_attivita/count?organisation=${orgId}&org_feedback_eq=pending&speaker_feedback_ne=refused&${query}`;
    }
  }

  return fetch(req, requestOptions).then(handleResponse);
}

function getSpeakerBookingRequests(
  start,
  limit,
  invoice,
  searchKey,
  idRelatore,
  ordine,
  ascendente
) {
  var qs = require("qs");

  const _start = start === undefined ? 0 : start;
  const _limit = limit === undefined ? -1 : limit;
  const _invoice = invoice === undefined ? false : invoice;
  const _searchKey = searchKey === undefined ? "" : searchKey;

  var req = ``;

  const invoiceQuery = qs.stringify({
    _where: {
      // even if the speaker has not confirmed/refused yet (i.e. it is pending), if the org has already refused, it is considered "processed"
      _or: [{ speaker_feedback_ne: "pending" }, { org_feedback_eq: "refused" }],
    },
  });

  ordine = encodeURIComponent(ordine);

  const requestOptions = { method: "GET", headers: authHeader() };
  if (_searchKey === "") {
    if (_invoice) {
      req = `${config.api.url}/prenotazioni_attivita/?relatori=${idRelatore}&_start=${_start}&_limit=${_limit}&_ordine=${ordine}&_ascendente=${ascendente}&${invoiceQuery}`;
    } else {
      req = `${config.api.url}/prenotazioni_attivita/?relatori=${idRelatore}&_start=${_start}&_limit=${_limit}&_ordine=${ordine}&_ascendente=${ascendente}&speaker_feedback_eq=pending&org_feedback_ne=refused`;
    }
  } else {
    const query = qs.stringify({
      _where: {
        _or: [{ nome_contains: _searchKey }, { cognome_contains: _searchKey }],
      },
    });

    if (_invoice) {
      req = `${config.api.url}/prenotazioni_attivita/?relatori=${idRelatore}&_start=${_start}&_limit=${_limit}&_ordine=${ordine}&_ascendente=${ascendente}&${invoiceQuery}&${query}`;
    } else {
      req = `${config.api.url}/prenotazioni_attivita/?relatori=${idRelatore}&_start=${_start}&_limit=${_limit}&_ordine=${ordine}&_ascendente=${ascendente}&speaker_feedback_eq=pending&org_feedback_ne=refused&${query}`;
    }
  }

  return fetch(req, requestOptions).then(handleResponse);
}

function countSpeakerBookingRequests(invoice, searchKey, idRelatore) {
  const _invoice = invoice === undefined ? false : invoice;

  var req = ``;
  var qs = require("qs");

  const invoiceQuery = qs.stringify({
    _where: {
      // even if the speaker has not confirmed/refused yet (i.e. it is pending), if the org has already refused, it is considered "processed"
      _or: [{ speaker_feedback_ne: "pending" }, { org_feedback_eq: "refused" }],
    },
  });

  const requestOptions = { method: "GET", headers: authHeader() };
  const _searchKey = searchKey;

  if (_searchKey === "") {
    if (_invoice) {
      req = `${config.api.url}/prenotazioni_attivita/count?relatori=${idRelatore}&${invoiceQuery}`;
    } else {
      req = `${config.api.url}/prenotazioni_attivita/count?relatori=${idRelatore}&speaker_feedback_eq=pending&org_feedback_ne=refused`;
    }
  } else {
    const query = qs.stringify({
      _where: {
        _or: [{ nome_contains: _searchKey }, { cognome_contains: _searchKey }],
      },
    });

    if (_invoice) {
      req = `${config.api.url}/prenotazioni_attivita/count?relatori=${idRelatore}&${invoiceQuery}&${query}`;
    } else {
      req = `${config.api.url}/prenotazioni_attivita/count?relatori=${idRelatore}&speaker_feedback_eq=pending&org_feedback_ne=refused&${query}`;
    }
  }

  return fetch(req, requestOptions).then(handleResponse);
}

function getSingleEvent(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/eventi_childs/eventoOnline/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getVisits(date) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/prenotazioni_ingressi/month/${date}`,
    requestOptions
  ).then(handleResponse);
}

function getHistogram(date) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/slot_orarie/istogramma/${date}`,
    requestOptions
  ).then(handleResponse);
}

function getClosingDay(date) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/museis/giorniChiusura/${date}`,
    requestOptions
  ).then(handleResponse);
}

function getActivityStructure(
  id,
  isPrivate = false,
  invoice = true,
  mandatoryIVA = false
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      isPrivate,
      invoice,
      mandatoryIVA,
    }),
  };
  return fetch(
    `${config.api.url}/attivita/struttura/${id}`,
    requestOptions
  ).then(handleResponse);
}

function createEvent(
  nome_evento,
  titolo_email,
  luogo_evento,
  posti_totali,
  descrizione_evento,
  categoria_evento,
  gestione_esterna,
  link_esterno,
  ora_inizio,
  ora_fine,
  giorni_ripetizione,
  flag_bambini,
  prenotabile,
  prenotabile_card,
  colore,
  ticketsType,
  uniqueEvent,
  immagine_evento,
  flag_attesa
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      nome_evento,
      titolo_email,
      luogo_evento,
      posti_totali,
      descrizione_evento,
      categoria_evento,
      gestione_esterna,
      link_esterno,
      ora_inizio,
      ora_fine,
      giorni_ripetizione,
      flag_bambini,
      prenotabile,
      prenotabile_card,
      colore,
      ticketsType,
      uniqueEvent,
      immagine_evento,
      flag_attesa,
    }),
  };

  return fetch(`${config.api.url}/schema_eventis`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function createTicketsType(nome, prezzo, disponibilita, id_evento) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      nome,
      prezzo,
      disponibilita,
      id_evento,
    }),
  };

  return fetch(`${config.api.url}/biglietti-eventis`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function createActivity(
  nome,
  abstract,
  descrizione,
  categoria,
  durata,
  capienza,
  min_partecipanti,
  modello_prenotazione,
  biglietti,
  spazi,
  pagamento_individuale
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      nome,
      abstract,
      descrizione,
      categoria,
      durata,
      capienza,
      min_partecipanti,
      modello_prenotazione,
      biglietti,
      spazi,
      pagamento_individuale,
    }),
  };

  return fetch(`${config.api.url}/attivita`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function modifyEvent(
  id,
  nome_evento,
  titolo_email,
  luogo_evento,
  posti_totali,
  descrizione_evento,
  categoria_evento,
  gestione_esterna,
  link_esterno,
  ora_inizio,
  ora_fine,
  giorni_ripetizione,
  flag_bambini,
  prenotabile,
  colore,
  prenotabile_card,
  ticketsType,
  flag_attesa
) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      nome_evento,
      titolo_email,
      luogo_evento,
      posti_totali,
      descrizione_evento,
      categoria_evento,
      gestione_esterna,
      link_esterno,
      ora_inizio,
      ora_fine,
      giorni_ripetizione,
      flag_bambini,
      prenotabile,
      colore,
      prenotabile_card,
      flag_attesa,
    }),
  };

  return fetch(`${config.api.url}/schema_eventis/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function modifyTicketsType(element) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      nome: element.nome,
      prezzo: element.prezzo,
      disponibilita: element.disponibilita,
    }),
  };

  return fetch(
    `${config.api.url}/biglietti-eventis/${element.id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function modifyActivity(
  id,
  nome,
  abstract,
  descrizione,
  categoria,
  durata,
  capienza,
  min_partecipanti,
  modello_prenotazione,
  biglietti,
  spazi,
  pagamento_individuale
) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      id,
      nome,
      abstract,
      descrizione,
      categoria,
      durata,
      capienza,
      min_partecipanti,
      modello_prenotazione,
      biglietti,
      spazi,
      pagamento_individuale,
    }),
  };

  return fetch(`${config.api.url}/attivita/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function modifyActivityRes(id, org_feedback) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      org_feedback,
    }),
  };

  return fetch(
    `${config.api.url}/prenotazioni_attivita/feedback/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function modifySpeakerRes(id, speaker_feedback) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      speaker_feedback,
    }),
  };

  return fetch(
    `${config.api.url}/prenotazioni_attivita/feedback/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function modifyModel(id, modello_prenotazione) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      modello_prenotazione,
    }),
  };

  return fetch(`${config.api.url}/attivita/struttura/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function modifyTickets(id, biglietti) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      biglietti,
    }),
  };

  return fetch(`${config.api.url}/attivita/struttura/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteEventCategory(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/categorie_eventis/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function disableEventCategory(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(
    `${config.api.url}/categorie_eventis/disable/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function enableEventCategory(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(
    `${config.api.url}/categorie_eventis/enable/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteActivityCategory(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/categorie_attivita/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteEvent(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/eventi_childs/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteTicketsType(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/biglietti-eventis/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteActivity(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/attivita/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function createCategory(nome_categoria) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      nome_categoria,
    }),
  };

  return fetch(`${config.api.url}/categorie_eventis`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function createActivityCategory(nome) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      nome,
    }),
  };

  return fetch(`${config.api.url}/categorie_attivita`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function modifyCategory(id, nome_categoria) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      nome_categoria,
    }),
  };

  return fetch(`${config.api.url}/categorie_eventis/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function modifyActivityCategory(id, nome) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      nome,
    }),
  };

  return fetch(`${config.api.url}/categorie_attivita/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteSchemaEvent(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/schema_eventis/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function retrieveImg(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/upload/files/${id}`, requestOptions).then(
    handleResponse
  );
}

function getGroup(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/gruppis/${id}`, requestOptions).then(
    handleResponse
  );
}
